import api from "../apis";
import _ from "lodash";

export const PERMISSION_CREATE = "setting/PERMISSION_CREATE";
export const PERMISSION_FETCH = "setting/PERMISSION_FETCH";
export const PERMISSION_FETCH_BY_ID = "setting/ PERMISSION_FETCH_BY_ID";
export const PERMISSION_DELETE = "setting/PERMISSION_DELETE";
export const PERMISSION_EDIT = "setting/PERMISSION_EDIT";
export const PERMISSION_LOADING = "setting/PERMISSION_LOADING";
export const PERMISSION_CLEAR_RESULT = "setting/PERMISSION_CLEAR_RESULT";
export const PERMISSION_CHANGE_PAGE = "setting/PERMISSION_CHANGE_PAGE";
export const PERMISSION_CHANGE_PAGE_SIZE =
  "setting/PERMISSION_CHANGE_PAGE_SIZE";
export const PERMISSION_FORCE_RELOAD = "setting/PERMISSION_FORCE_RELOAD";
export const PERMISSION_SET_FILTER = "setting/PERMISSION_SET_FILTER";

export const USERS_CREATE = "setting/USERS_CREATE";
export const USERS_FETCH = "setting/USERS_FETCH";
export const USERS_FETCH_BY_ID = "setting/USERS_FETCH_BY_ID";
export const USERS_DELETE = "setting/USERS_DELETE";
export const USERS_EDIT = "setting/USERS_EDIT";
export const USERS_LOADING = "setting/USERS_LOADING";
export const USERS_CLEAR_RESULT = "setting/USERS_CLEAR_RESULT";
export const USERS_CHANGE_PAGE = "setting/USERS_CHANGE_PAGE";
export const USERS_CHANGE_PAGE_SIZE = "setting/USERS_CHANGE_PAGE_SIZE";
export const USERS_FORCE_RELOAD = "setting/USERS_FORCE_RELOAD";
export const USERS_SET_FILTER = "setting/USERS_SET_FILTER";

export const USER_GROUP_CREATE = "setting/USER_GROUP_CREATE";
export const USER_GROUP_FETCH = "setting/USER_GROUP_FETCH";
export const USER_GROUP_FETCH_BY_ID = "setting/USER_GROUP_FETCH_BY_ID";
export const USER_GROUP_DELETE = "setting/USER_GROUP_DELETE";
export const USER_GROUP_EDIT = "setting/USER_GROUP_EDIT";
export const USER_GROUP_LOADING = "setting/USER_GROUP_LOADING";
export const USER_GROUP_CLEAR_RESULT = "setting/USER_GROUP_CLEAR_RESULT";
export const USER_GROUP_CHANGE_PAGE = "setting/USER_GROUP_CHANGE_PAGE";
export const USER_GROUP_CHANGE_PAGE_SIZE =
  "setting/USER_GROUP_CHANGE_PAGE_SIZE";
export const USER_GROUP_FORCE_RELOAD = "setting/USER_GROUP_FORCE_RELOAD";
export const USER_GROUP_SET_FILTER = "setting/USER_GROUP_SET_FILTER";

export const NOTIFICATION_CREATE = "setting/NOTIFICATION_CREATE";
export const NOTIFICATION_FETCH = "setting/NOTIFICATION_FETCH";
export const NOTIFICATION_FETCH_BY_ID = "setting/NOTIFICATION_FETCH_BY_ID";
export const NOTIFICATION_DELETE = "setting/NOTIFICATION_DELETE";
export const NOTIFICATION_EDIT = "setting/NOTIFICATION_EDIT";
export const NOTIFICATION_LOADING = "setting/NOTIFICATION_LOADING";
export const NOTIFICATION_CLEAR_RESULT = "setting/NOTIFICATION_CLEAR_RESULT";
export const NOTIFICATION_CHANGE_PAGE = "setting/NOTIFICATION_CHANGE_PAGE";
export const NOTIFICATION_CHANGE_PAGE_SIZE =
  "setting/NOTIFICATION_CHANGE_PAGE_SIZE";
export const NOTIFICATION_FORCE_RELOAD = "setting/NOTIFICATION_FORCE_RELOAD";
export const NOTIFICATION_SET_FILTER = "setting/NOTIFICATION_SET_FILTER";

const defaultState = {
  permission: {},
  users: {},
  userGroup: {},
  notification: {},
  metaPermission: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  metaUsers: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  metaUserGroup: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  metaNotification: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case PERMISSION_FETCH:
      return {
        ...state,
        permission: action.payload,
        metaPermission: {
          ...state.metaPermission,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case PERMISSION_FETCH_BY_ID:
      return {
        ...state,
        permission: {
          ...state.permission,
          [action.payload.id]: action.payload,
        },
      };
    case PERMISSION_DELETE:
      return {
        ...state,
        permission: _.omit(state.permission, action.payload),
      };
    case PERMISSION_EDIT:
      return {
        ...state,
        permission: {
          ...state.permission,
          [action.payload.id]: action.payload,
        },
      };
    case PERMISSION_CHANGE_PAGE:
      return {
        ...state,
        metaPermission: {
          ...state.metaPermission,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case PERMISSION_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaPermission: {
          ...state.metaPermission,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case PERMISSION_FORCE_RELOAD:
      return {
        ...state,
        metaPermission: {
          ...state.metaPermission,
          forceReload: ++state.metaPermission.forceReload,
        },
      };
    case PERMISSION_SET_FILTER:
      return {
        ...state,
        metaPermission: {
          ...state.metaPermission,
          filter: action.payload,
          page: 0,
        },
      };
    case PERMISSION_CLEAR_RESULT:
      return {
        ...state,
        permission: {},
        metaPermission: {
          ...state.metaPermission,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case USERS_FETCH:
      return {
        ...state,
        users: action.payload,
        metaUsers: {
          ...state.metaUsers,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case USERS_FETCH_BY_ID:
      return {
        ...state,
        users: { ...state.users, [action.payload.id]: action.payload },
      };
    case USERS_DELETE:
      return {
        ...state,
        users: _.omit(state.users, action.payload),
      };
    case USERS_EDIT:
      return {
        ...state,
        users: { ...state.users, [action.payload.id]: action.payload },
      };
    case USERS_CHANGE_PAGE:
      return {
        ...state,
        metaUsers: {
          ...state.metaUsers,
          page: action.payload,
        },
      };
    case USERS_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaUsers: {
          ...state.metaUsers,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case USERS_CLEAR_RESULT:
      return {
        ...state,
        users: {},
        metaUsers: {
          ...state.metaUsers,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case USERS_FORCE_RELOAD:
      return {
        ...state,
        metaUsers: {
          ...state.metaUsers,
          forceReload: ++state.metaUsers.forceReload,
        },
      };
    case USERS_SET_FILTER:
      return {
        ...state,
        metaUsers: {
          ...state.metaUsers,
          filter: action.payload,
          page: 0,
        },
      };
    case USER_GROUP_FETCH:
      return {
        ...state,
        userGroup: action.payload,
        metaUserGroup: {
          ...state.metaUsers,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case USER_GROUP_FETCH_BY_ID:
      return {
        ...state,
        userGroup: { ...state.userGroup, [action.payload.id]: action.payload },
      };
    case USER_GROUP_DELETE:
      return {
        ...state,
        userGroup: _.omit(state.users, action.payload),
      };
    case USER_GROUP_EDIT:
      return {
        ...state,
        userGroup: { ...state.userGroup, [action.payload.id]: action.payload },
      };
    case USER_GROUP_CHANGE_PAGE:
      return {
        ...state,
        metaUserGroup: {
          ...state.metaUserGroup,
          page: action.payload,
        },
      };
    case USER_GROUP_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaUserGroup: {
          ...state.metaUserGroup,
          page: 0,
          pageSize: action.payload,
        },
      };
    case USER_GROUP_CLEAR_RESULT:
      return {
        ...state,
        userGroup: {},
        metaUserGroup: {
          ...state.metaUserGroup,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case USER_GROUP_FORCE_RELOAD:
      return {
        ...state,
        metaUserGroup: {
          ...state.metaUserGroup,
          forceReload: ++state.metaUserGroup.forceReload,
        },
      };
    case USER_GROUP_SET_FILTER:
      return {
        ...state,
        metaUserGroup: {
          ...state.metaUserGroup,
          filter: action.payload,
          page: 0,
        },
      };
    case NOTIFICATION_FETCH:
      return {
        ...state,
        notification: action.payload,
        metaNotification: {
          ...state.metaNotification,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case NOTIFICATION_FETCH_BY_ID:
      return {
        ...state,
        notification: {
          ...state.notification,
          [action.payload.id]: action.payload,
        },
      };
    case NOTIFICATION_DELETE:
      return {
        ...state,
        notification: _.omit(state.notification, action.payload),
      };
    case NOTIFICATION_EDIT:
      return {
        ...state,
        notification: {
          ...state.notification,
          [action.payload.id]: action.payload,
        },
      };
    case NOTIFICATION_CHANGE_PAGE:
      return {
        ...state,
        metaNotification: {
          ...state.metaNotification,
          page: action.payload,
        },
      };
    case NOTIFICATION_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaUsers: {
          ...state.metaUsers,
          page: action.payload,
          pageSize: action.payload,
        },
      };
    case NOTIFICATION_CLEAR_RESULT:
      return {
        ...state,
        users: {},
        metaNotification: {
          ...state.metaNotification,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case NOTIFICATION_FORCE_RELOAD:
      return {
        ...state,
        metaNotification: {
          ...state.metaNotification,
          forceReload: ++state.metaNotification.forceReload,
        },
      };
    case NOTIFICATION_SET_FILTER:
      return {
        ...state,
        metaNotification: {
          ...state.metaNotification,
          filter: action.payload,
          page: 0,
        },
      };
    default:
      return state;
  }
}

export const permissionFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(permissionLoading(true));
    try {
      const response = await api.get(`api/permission`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: PERMISSION_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(permissionClearResult());
      throw e;
    }
  };

export const permissionLoading = (isLoading = true) => {
  return { type: PERMISSION_LOADING, payload: isLoading };
};

export const permissionClearResult = () => {
  return { type: PERMISSION_CLEAR_RESULT };
};

export const permissionFetchById = (id) => async (dispatch) => {
  const response = await api.get(`api/permission/${id}`);
  dispatch({ type: PERMISSION_FETCH_BY_ID, payload: response.data });
};

export const permissionCreate = (formsValues) => async (dispatch) => {
  await api.post("api/permission", formsValues);
};

export const permissionEdit = (id, formValues) => async (dispatch) => {
  //   formValues._method = "PUT";
  await api.put(`api/permission/${id}`, formValues);
  // dispatch({ type: EDIT_ARREST, payload: response.data });
};

export const permissionDelete = (id) => async (dispatch) => {
  await api.delete(`api/permission/${id}`);
  dispatch({ type: PERMISSION_DELETE, payload: id });
  // if (deleteId === deleteIdRef.current) {
  // }
};
export const permissionForceReload = () => {
  return { type: PERMISSION_FORCE_RELOAD };
};
export const permissionChangePage = (page) => {
  return { type: PERMISSION_CHANGE_PAGE, payload: page };
};

export const permissionChangePageSize = (pageSize) => {
  return { type: PERMISSION_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const permissionSetFilter = (filterValue) => {
  return { type: PERMISSION_SET_FILTER, payload: filterValue };
};

export const usersFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(usersLoading(true));
    try {
      const response = await api.get(`api/users`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: USERS_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(usersClearResult());
      throw e;
    }
  };

export const usersLoading = (isLoading = true) => {
  return { type: USERS_LOADING, payload: isLoading };
};

export const usersClearResult = () => {
  return { type: USERS_CLEAR_RESULT };
};

export const usersFetchById = (id) => async (dispatch) => {
  const response = await api.get(`api/users/${id}`);
  dispatch({ type: USERS_FETCH_BY_ID, payload: response.data });
};

export const usersCreate = (formsValues) => async (dispatch) => {
  await api.post("api/users", formsValues);
};

export const usersCreateExternal = (formsValues) => async (dispatch) => {
  await api.post("api/external/users", formsValues);
};

export const usersEdit = (id, formValues) => async (dispatch) => {
  //   formValues._method = "PUT";
  await api.put(`api/users/${id}`, formValues);
  // dispatch({ type: EDIT_ARREST, payload: response.data });
};

export const usersDelete = (id) => async (dispatch) => {
  await api.delete(`api/users/${id}`);
  dispatch({ type: USERS_DELETE, payload: id });
  // if (deleteId === deleteIdRef.current) {
  // }
};

export const usersChangePage = (page) => {
  return { type: USERS_CHANGE_PAGE, payload: page };
};

export const usersChangePageSize = (pageSize) => {
  return { type: USERS_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const usersSetFilter = (filterValue) => {
  return { type: USERS_SET_FILTER, payload: filterValue };
};

export const usersForceReload = () => {
  return { type: USERS_FORCE_RELOAD };
};

export const userGroupFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(userGroupLoading(true));
    try {
      const response = await api.get(`api/users`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: USER_GROUP_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(userGroupClearResult());
      throw e;
    }
  };

export const userGroupLoading = (isLoading = true) => {
  return { type: USER_GROUP_LOADING, payload: isLoading };
};

export const userGroupClearResult = () => {
  return { type: USER_GROUP_CLEAR_RESULT };
};

export const userGroupFetchById = (id) => async (dispatch) => {
  const response = await api.get(`api/users/${id}`);
  dispatch({ type: USER_GROUP_FETCH_BY_ID, payload: response.data });
};

export const userGroupCreate = (formsValues) => async (dispatch) => {
  await api.post("api/users", formsValues);
};

export const userGroupEdit = (id, formValues) => async (dispatch) => {
  await api.put(`api/users/${id}`, formValues);
  // dispatch({ type: EDIT_ARREST, payload: response.data });
};

export const userGroupDelete = (id) => async (dispatch) => {
  await api.delete(`api/users/${id}`);
  dispatch({ type: USER_GROUP_DELETE, payload: id });
};

export const userGroupChangePage = (page) => {
  return { type: USER_GROUP_CHANGE_PAGE, payload: page };
};

export const userGroupChangePageSize = (pageSize) => {
  return { type: USER_GROUP_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const userGroupSetFilter = (filterValue) => {
  return { type: USER_GROUP_SET_FILTER, payload: filterValue };
};

export const userGroupForceReload = () => {
  return { type: USER_GROUP_FORCE_RELOAD };
};

export const notificationFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(notificationLoading(true));
    try {
      const response = await api.get(`api`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: NOTIFICATION_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(notificationClearResult());
      throw e;
    }
  };

export const notificationLoading = (isLoading = true) => {
  return { type: NOTIFICATION_LOADING, payload: isLoading };
};

export const notificationClearResult = () => {
  return { type: NOTIFICATION_CLEAR_RESULT };
};

export const notificationFetchById = (id) => async (dispatch) => {
  const response = await api.get(`api/systemsettings/${id}`);
  dispatch({ type: NOTIFICATION_FETCH_BY_ID, payload: response.data });
};

export const notificationUpdate = (formsValues) => async (dispatch) => {
  await api.post("api/systemsettings", formsValues);
};

export const notificationEdit = (id, formValues) => async (dispatch) => {
  await api.put(`api/systemsettings/${id}`, formValues);
  // dispatch({ type: EDIT_ARREST, payload: response.data });
};

export const notificationDelete = (id) => async (dispatch) => {
  await api.delete(`api/systemsettings/${id}`);
  dispatch({ type: NOTIFICATION_DELETE, payload: id });
};

export const notificationChangePage = (page) => {
  return { type: NOTIFICATION_CHANGE_PAGE, payload: page };
};

export const notificationChangePageSize = (pageSize) => {
  return { type: NOTIFICATION_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const notificationSetFilter = (filterValue) => {
  return { type: NOTIFICATION_SET_FILTER, payload: filterValue };
};

export const notificationForceReload = () => {
  return { type: NOTIFICATION_FORCE_RELOAD };
};
