import { LoadingButton } from "@mui/lab";
import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";

import { Add, Download, Print, Search } from "@mui/icons-material";
import fileDownload from "js-file-download";
import { Link } from "react-router-dom";
import api from "../../../../apis";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";

export default function UserSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  pristine,
  form,
  values,
  modifiedSinceLastSubmit,

  page,
  pageSize,
}) {
  const status = [
    { value: "1", label: "เปิดใช้งาน" },
    { value: "0", label: "ปิดใช้งาน" },
  ];

  const qState = useSelector((state) => state.auth.user.permissions);

  // const [pdfDownloading, setPdfDownloading] = useState(false);
  // const [loadingPDF, setLoadingPDF] = useState(false);
  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const openDowload = Boolean(anchorElDowload);
  const [anchorElPrint, setAnchorElPrint] = React.useState(null);
  const openPrint = Boolean(anchorElPrint);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const { data: roles } = useQuery(
    ["roles"],
    () =>
      api.get(`/api/roles`).then((res) =>
        res.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      )
    // { staleTime: 60 * 1000 }
  );

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const handleClickPrint = (event) => {
    setAnchorElPrint(event.currentTarget);
  };
  const handleClosePrint = () => {
    setAnchorElPrint(null);
  };

  const getFileDownload = async (exportType) => {
    const res = await api.get("/api/users", {
      responseType: "blob",
      params: {
        page: page + 1,
        page_size: pageSize,
        export: exportType,
        status: values?.status,
        org_code: values?.policestation_org_code?.org_code,
        role: values?.role,
        search: values?.search,
      },
    });
    let headerLine = res.headers["content-disposition"];
    let rawFilename = headerLine.split("filename=")[1];
    let filename = rawFilename.replace(/[^A-Za-z0-9.]/g, "");
    fileDownload(res.data, filename);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {qState?.setting_user?.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          {showStatusField && (
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FieldAutoComplete
                name="status"
                label="สถานะ"
                options={status}
                controlProp={{
                  sx: {
                    width: "100%",
                  },
                }}
                // inputProps={{ sx: { fullWidth: true } }}
                // controlProp={{ sx: { fullWidth: true } }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldAsyncAutoComplete
              name="policestation_org_code"
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              basePath="/api/policestations"
              itemFormat={(row) => {
                return {
                  value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
                  label: row.ORG_ABBR,
                };
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldAutoComplete
              name="role"
              label="สิทธิ์การใช้งาน"
              options={roles || []}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              //   inputProps={{ sx: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={12} lg={8} xl={3}>
            <FieldInput
              name="search"
              label="คำค้นหา"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} mb={2}>
        {qState?.setting_user?.includes("search") && (
          <Grid item md={12} lg={4}>
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          </Grid>
        )}

        <Grid
          item
          md={12}
          lg={qState?.setting_user?.includes("search") ? 8 : 12}
        >
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {/* <Button
              id="export-button-excel"
              variant="outlined"
              startIcon={<CloudSync />}
              aria-haspopup="true"
              onClick={handleToggleModalImport}
            >
              ตรวจสอบข้อมูล
            </Button> */}
            <Button
              id="export-button-excel"
              variant="outlined"
              startIcon={<Print />}
              aria-controls={openPrint ? "menu-print" : undefined}
              aria-haspopup="true"
              aria-expanded={openPrint ? "true" : undefined}
              onClick={handleClickPrint}
            >
              พิมพ์
            </Button>
            <Menu
              id="menu-print"
              anchorEl={anchorElPrint}
              open={openPrint}
              onClose={handleClosePrint}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {
                  const res = await api.get("/api/users", {
                    responseType: "blob",
                    params: {
                      page: page + 1,
                      page_size: pageSize,
                      export: "pdf",
                      status: values?.status,
                      org_code: values?.policestation_org_code?.org_code,
                      role: values?.role,
                      search: values?.search,
                    },
                  });

                  // Create a URL from the Blob
                  const blob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  const url = URL.createObjectURL(blob);

                  // Open the PDF in a new tab and trigger the print dialog
                  const newWindow = window?.open(url);
                  if (newWindow) {
                    newWindow.onload = () => {
                      newWindow?.focus();
                      newWindow?.print();
                    };
                  }

                  // Optional: Revoke the URL to free up memory
                  URL?.revokeObjectURL(url);

                  // ReportPDF(usersData?.data, true);
                }}
              >
                Preview PDF
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  getFileDownload("pdf");
                }}
              >
                Dowload PDF{" "}
              </MenuItem>
            </Menu>
            {qState?.setting_user?.includes("download") && (
              <LoadingButton
                id="export-button-excel"
                type="button"
                variant="outlined"
                startIcon={<Download />}
                loadingPosition="start"
                // loading={pdfDownloading}
                // disabled={loadingPDF}
                // onClick={() => handleDownload("/api/", setPdfDownloading)}
                aria-controls={openDowload ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDowload ? "true" : undefined}
                onClick={handleClickDowload}
              >
                ดาวน์โหลด
              </LoadingButton>
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorElDowload}
              open={openDowload}
              onClose={handleCloseDownload}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {
                  getFileDownload("excel");
                }}
              >
                Excel
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  getFileDownload("csv");
                }}
              >
                CSV
              </MenuItem>
            </Menu>
            {qState?.setting_user?.includes("create") && (
              <Button
                variant="contained"
                startIcon={<Add />}
                color="success"
                component={Link}
                to="/user/add"
              >
                เพิ่ม
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}
// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
