import { Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Autocomplete,
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import api from "../../../../apis";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const optionStatus = [
  { label: "เปิดใช้งาน", value: true },
  { label: "ปิดใช้งาน", value: false },
];

export default function ChannelSendingSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  pageSize,
  page
}) {
  const channel = [
    { value: "bank", label: "ธนาคาร" },
    { value: "telco", label: "โทรศัพท์" },
  ];
  const qState = useSelector((state) => state.auth.user.permissions);

  const [loadingFile, setLoadingFile] = useState(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };
  const [anchorElDowload, setAnchorElDowload] = useState(null);

  const openDowload = Boolean(anchorElDowload);

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const onStatusChange = (e, value) => {
    form.change("status", value !== null ? value.value : "");
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {qState?.setting_agency?.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item xs={12} md={4}>
            <Field name="status">
              {({ input }) => (
                <Autocomplete
                  options={optionStatus}
                  getOptionLabel={(option) => option.label}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="สถานะใช้งาน" />
                  )}
                  onChange={onStatusChange}
                  value={
                    optionStatus != null
                      ? optionStatus.find(
                          (option) => option.value === input.value
                        ) || null
                      : null
                  }
                />
              )}
            </Field>
          </Grid>
          {showStatusField && (
            <Grid item xs={12} md={3}>
              <FieldAutoComplete
                name="channel"
                label="ประเภทบริการ"
                options={channel}
                controlProp={{
                  sx: {
                    width: "100%",
                  },
                }}
              />
            </Grid>
          )}
          {/* <Grid item xs={12} md={3}>
          <FieldAsyncSelect
            name="bank_id"
            label="หน่วยงานภายนอก"
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            basePath={`/api/banks`}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
          />
        </Grid> */}
          <Grid item xs={12} md={3}>
            <FieldInput
              name="search_agency_name"
              label="คำค้นหา"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} mb={2}>
        {qState?.setting_agency?.includes("search") && (
          <Grid item md={12} lg={4}>
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          </Grid>
        )}

        {qState?.setting_agency?.includes("download") && (
          <Grid
            item
            md={12}
            lg={qState?.setting_agency?.includes("search") ? 8 : 12}
          >
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <LoadingButton
                id="export-button-excel"
                type="button"
                variant="outlined"
                startIcon={<Download />}
                loadingPosition="start"
                // loading={pdfDownloading}
                // disabled={loadingPDF}
                // onClick={() => handleDownload("/api/", setPdfDownloading)}
                aria-controls={openDowload ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDowload ? "true" : undefined}
                onClick={handleClickDowload}
              >
                ดาวน์โหลด
              </LoadingButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorElDowload}
                open={openDowload}
                onClose={handleCloseDownload}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={async () => {
                    try {
                      handleCloseDownload(false);
                      setLoadingFile(true);

                      const res = await api.get(
                        "/api/banks",
                        {
                          responseType: "blob",
                          params: {
                            page_size:pageSize,
                            page: page,
                            export: "excel",
                            ...values
                          },
                        }
                      );

                      let fileName =
                        res?.headers["content-disposition"].split(
                          "filename="
                        )[1];

                      fileDownload(res.data, fileName);
                    } catch (error) {
                      console.log("Error");
                    } finally {
                      setLoadingFile(false);
                    }
                  }}
                >
                  Excel
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    try {
                      handleCloseDownload(false);
                      setLoadingFile(true);

                      const res = await api.get(
                        "/api/banks",
                        {
                          responseType: "blob",
                          params: {
                            page_size:pageSize,
                            page: page,
                            export: "csv",
                            ...values
                          },
                        }
                      );

                      let fileName =
                        res?.headers["content-disposition"].split(
                          "filename="
                        )[1];

                      fileDownload(res.data, fileName);
                    } catch (error) {
                      console.log("Error");
                    } finally {
                      setLoadingFile(false);
                    }
                  }}
                >
                  CSV
                </MenuItem>
              </Menu>
            </Stack>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
