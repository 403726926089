import { Edit } from "@mui/icons-material";
import {
  Alert,
  Button,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import _ from "lodash";
import ProfileForm, { validateRules, validate } from "./ChPasswordForm";
import api, { setAuthorizationToken } from "../../apis";
import { FORM_ERROR } from "final-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { setAuthorizationToken as setAuthorizationToken2 } from "../../apis/apigw";
import LocalStorageService from "../../helpers/LocalStorageService";

export default function ProfileViewEditPage() {
  const isInternal = useSelector((state) => state.auth.user?.isInternal);
  const [item, setItem] = useState({});
  const [editMode, setEditMode] = useState(false);

  // for fetch error
  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    api
      .get(`api/me`)
      .then(({ data }) => {
        // console.log(data.permisstions.map((v) => v.option.map((v) => v.info)));
        isSubscribed && setItem(data);
      })
      .catch(({ response }) => {
        if (response && response.status === 404) {
          SetFetchError("เกิดข้อผิดพลาด");
        }
        SetFetchError("เกิดข้อผิดพลาด");
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onSubmit = (formData, form) => {
    return api
      .put(
        isInternal ? "api/change_password" : "api/external/change_password",
        formData
      )
      .then((response) => {
        LocalStorageService.setToken({ access_token: response.data.token });
        setAuthorizationToken(LocalStorageService.getAccessToken());
        setAuthorizationToken2(LocalStorageService.getIaccessToken());
        setEditMode(false);
        form.reset(formData);
        toast.success("เปลี่ยนรหัสผ่านสำเร็จ");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }
          normalizeKeyError[FORM_ERROR] = response.data.message;
          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  return (
    <>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          {/* <AccountCircle fontSize="large" /> */}
          <Typography variant="h5">เปลี่ยนรหัสผ่าน </Typography>
          {!editMode && (
            <Button
              size="large"
              onClick={() => setEditMode(true)}
              startIcon={<Edit fontSize="inherit" />}
            >
              แก้ไข
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Form
              onSubmit={onSubmit}
              initialValues={mapToInit(item)}
              component={ProfileForm}
              readOnly={!editMode}
              onCancel={(form) => {
                form.restart();
                setEditMode(false);
              }}
              validate={validate}
            />
          </Paper>
        )}
      </Grid>
    </>
  );
}

const mapToInit = (item) => {
  return _.pick(item, Object.keys(validateRules));
};
