import { FileDownload, HourglassBottom, Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import {
  changePageSize,
  clearResult,
  fetch,
  forceReload,
  setFilter,
  excludeBanks,
} from "../../../../ducks/sumRequestList";
import { thousands_separators } from "../../../../helpers/stringFormater";
import CheckedSumRequestSearchForm, { validate } from "./SumRequestSearchForm";
import api from "../../../../apis";
import fileDownload from "js-file-download";

const includeReqTypes = [2, 3, 9, 10];

export default function SumRequestPage() {
  const dispatch = useDispatch();
  const qState = useSelector((state) => state.auth.user.permissions);

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.sumRequestList.data);
  // const total = useSelector((state) => state.checkedSumRequestList.meta.total);
  const loading = useSelector((state) => state.sumRequestList.meta.loading);
  const forceReloadRef = useSelector(
    (state) => state.sumRequestList.meta.forceReloadRef
  );
  // const page = useSelector((state) => state.checkedSumRequestList.meta.page);
  const pageSize = useSelector((state) => state.sumRequestList.meta.pageSize);
  const filter = useSelector((state) => state.sumRequestList.meta.filter);

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!filter.status) {
      return undefined;
    }

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(fetch(filter, fetchId, fetchIdRef)).catch(({ response }) => {
      if (response && response.status === 500) {
        SetToastInfo({
          type: "error",
          message:
            "ผลลัพธ์อาจมากเกินไป โปรดระบุเงื่อนไข เช่น ช่วงวันที่ให้แคบลง",
        });
      } else {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, forceReloadRef, filter]);

  // alert state
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // action modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else if (reason.type === "success") {
          SetToastInfo(reason);
          dispatch(forceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const [disableAction, setDisableAction] = useState(false);

  const columns = [
    qState?.template_group?.includes("download")
      ? {
          field: "actions",
          headerName: "actions",
          sortable: false,
          width: 100,
          renderCell: ({ row }) => {
            return (
              <Stack
                spacing={1}
                direction="row"
                justifyContent="left"
                sx={{ width: "100%" }}
              >
                <DownloadActionBtn
                  disabled={disableAction}
                  onDownload={() => {
                    const sumReq = row;
                    setDisableAction(true);
                    return api
                      .post(
                        "/api/mgt/pol_requests/export_template_pack",
                        {
                          list_pol_request_id: sumReq.req_ids,
                          bank_id: sumReq.bank_id,
                          request_type_id: sumReq.request_type_id,
                          new_status: "sent_to_bank",
                        },
                        {
                          responseType: "arraybuffer",
                        }
                      )
                      .then((response) => {
                        // handleCancel({ type: "success", message: "สำเร็จ" });
                        const contentDisposition =
                          response.headers["content-disposition"];
                        const filename = /filename=.+/.exec(contentDisposition)
                          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                          : "download";
                        fileDownload(response.data, filename);
                      })
                      .catch((e) => {
                        console.log(e);
                        SetToastInfo({
                          type: "error",
                          payload: "เกิดข้อผิดพลาด",
                        });
                      })
                      .finally(() => {
                        setDisableAction(false);
                      });
                  }}
                />
              </Stack>
            );
          },
        }
      : null,
    // {
    //   field: "id",
    //   headerName: "ID",
    //   sortable: false,
    //   maxWidth: 70,
    // },
    {
      field: "bank_shortname",
      headerName: "ธนาคาร",
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "bank_name",
      headerName: "ธนาคาร",
      sortable: false,
      minWidth: 200,
      maxWidth: 250,
      valueGetter: (params) => {
        return params.row.bank?.name;
      },
    },
    {
      field: "request_type_name",
      headerName: "ประเภทคำขอ",
      sortable: false,
      minWidth: 280,
      maxWidth: 300,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },

    {
      field: "request_count",
      headerName: "จำนวนคำขอ",
      sortable: false,
      minWidth: 100,
      maxWidth: 120,
      type: "number",
      valueGetter: (params) => {
        const count = params.row.req_ids?.length;
        if (!count === undefined) {
          return undefined;
        }
        return thousands_separators(count);
      },
    },
    {
      field: "range_date",
      headerName: "ช่วงเวลา",
      sortable: false,
      minWidth: 200,
      maxWidth: 200,
      valueGetter: (params) => {
        const minDate = params.row.min_date
          ? format(params.row.min_date, "dd/MM/yyyy")
          : "undefined";
        const maxDate = params.row.max_date
          ? format(params.row.max_date, "dd/MM/yyyy")
          : "undefined";
        return `${minDate} - ${maxDate}`;
      },
    },
  ]?.filter(Boolean);

  const onSearchSubmit = (formValues) => {
    dispatch(setFilter(formValues));
  };

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h5">คำขอ(จัดกลุ่มแต่ละธนาคาร)</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          สำหรับคำขอชนิด A,B และธนาคารนอกเหนือจาก {excludeBanks.join(",")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 700,
            width: "100%",
          }}
        >
          {qState?.template_group?.includes("search") && (
            <Form
              validate={validate}
              onSubmit={onSearchSubmit}
              component={CheckedSumRequestSearchForm}
              loading={loading}
              onReset={() => {
                dispatch(clearResult());
                dispatch(setFilter({}));
              }}
              includeReqTypes={includeReqTypes}
              excludeBanks={excludeBanks}
            />
          )}

          <DataGrid
            disableColumnMenu
            columns={columns}
            rows={rows}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pageSize={pageSize}
            // pagination
            onPageSizeChange={(newPageSize) =>
              dispatch(changePageSize(newPageSize))
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.sumRequestList.meta.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(forceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}

function DownloadActionBtn({ disabled, onDownload }) {
  const [loading, setLoading] = useState(false);

  const ActionIcon = loading ? HourglassBottom : FileDownload;
  return (
    <Tooltip title="template" placement="top">
      <IconButton
        type="button"
        variant="contained"
        size="small"
        onClick={() => {
          setLoading(true);
          onDownload().finally(() => {
            setLoading(false);
          });
        }}
        disabled={disabled || false}
      >
        <ActionIcon
          size="inherit"
          color={disabled ? "palette.primary.light" : "primary"}
        />
      </IconButton>
    </Tooltip>
  );
}
