import { Paper, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import AccountForm, { validate, validateRules } from "./AccountForm";
import api from "../../../apis";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import { useSelector, useDispatch } from "react-redux";
import { forceReload as reloadAccountList } from "../../../ducks/accountList";
import { forceReload as reloadTransactionList } from "../../../ducks/transactionSearch";

const mapToInit = (item) => {
  return _.pick(item, Object.keys(validateRules));
};

export default function AccountEditPage({ accountId, handleCancel }) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const [channel, setChannel] = useState(1);

  const dispatch = useDispatch();
  const transactionForm = useSelector(
    (state) => state.finalForm?.transaction?.form
  );
  const transactionFormValues = useSelector(
    (state) => state.finalForm?.transaction?.state?.values
  );

  const onSubmit = (formValues) => {
    // console.log(formValue);
    const obj = {
      ...formValues,
      channel: channel,
    };
    return api
      .put(`api/cases/${caseId}/bank_accounts/${accountId}`, obj)
      .then(() => {
        // check field to reset
        const resetField = {};
        if (transactionFormValues?.src_bank_account_id === accountId) {
          resetField.src_bank_account_id = accountId;
        }
        if (transactionFormValues?.des_bank_account_id === accountId) {
          resetField.des_bank_account_id = accountId;
        }

        if (!_.isEmpty(resetField)) {
          transactionForm.batch(() => {
            resetField.src_bank_account_id &&
              transactionForm.change("src_bank_account_id", undefined); // listeners not notified
            resetField.des_bank_account_id &&
              transactionForm.change("des_bank_account_id", undefined); // listeners not notified
          });
          transactionForm.reset({ ...transactionFormValues, ...resetField });
        }
        dispatch(reloadTransactionList());
        dispatch(reloadAccountList());

        handleCancel();
        // console.log("submit success");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  const [item, setItem] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    if (item) {
      return undefined;
    }

    api
      .get(`api/cases/${caseId}/bank_accounts/${accountId}`)
      .then(({ data }) => {
        if (isSubscribed) {
          setItem(data);
        }
      })
      // .then(() => isSubscribed && setLoading(false))
      .catch(({ response }) => {
        if (response && response.status === 404) {
          handleCancel();
        }
      });
    return () => {
      isSubscribed = false;
    };
  }, [caseId, accountId, handleCancel, item]);

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        แก้ไขบัญชี
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={AccountForm}
            initialValues={mapToInit(item)}
            handleCancel={handleCancel}
            setChannel={setChannel}
          />
        )}
      </Paper>
    </>
  );
}
