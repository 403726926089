import { Autocomplete, Button, Grid, Stack, TextField } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { Form } from "react-final-form";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import SubmitBtn from "../../SubmitBtn";
import requestStatus from "../../../data/requestStatus";
import { useEffect, useState } from "react";
import api from "../../../apis";
import FieldInput from "../../form-fields/FieldInput";

export default function RequestListSearchForm({ onSubmit, loading, onReset }) {
  const [extAgency, setExtAgency] = useState([]);
  const [reqStatus, setReqStatus] = useState([]);
  const [reqTypes, setReqTypes] = useState([]);

  useEffect(() => {
    const list = Object.keys(requestStatus).map((status) => {
      const item = requestStatus[status];
      return { label: item.label, value: status };
    });
    setReqStatus(list);

    api.get("/api/banks").then(({ data }) => {
      setExtAgency(
        data.rows.map((row) => ({
          value: row.id,
          label: `${row.name} (${row.short_name})`,
        }))
      );
    });
    api.get("api/request/types").then(({ data }) => {
      setReqTypes(data);
    });
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      {({
        handleSubmit,
        error,
        submitting,
        pristine,
        initialValues,
        submitFailed,
        form,
        values,
        modifiedSinceLastSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <FieldAutoComplete
                  name="status"
                  label="สถานะ"
                  options={reqStatus}
                  controlProp={{ sx: { width: "100%" } }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FieldAutoComplete
                  name="bank"
                  label="หน่วยงานภายนอก"
                  options={extAgency}
                  controlProp={{ sx: { width: "100%" } }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FieldAutoComplete
                  name="request_type"
                  label="ประเภทคำขอ"
                  options={reqTypes}
                  controlProp={{ sx: { width: "100%" } }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4} xl={2}>
                <FieldDatePicker
                  name="start_date"
                  label="ตั้งแต่วันที่"
                  pickerProps={{
                    inputFormat: "dd/MM/yyyy",
                    disableFuture: true,
                    openTo: "year",
                    views: ["year", "month", "day"],
                    allowSameDateSelection: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2}>
                <FieldDatePicker
                  name="end_date"
                  label="จนถึงวันที่"
                  pickerProps={{
                    inputFormat: "dd/MM/yyyy",
                    disableFuture: true,
                    openTo: "year",
                    views: ["year", "month", "day"],
                    allowSameDateSelection: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <FieldInput
                  name="search"
                  label="คำค้นหา"
                  variant="outlined"
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <SubmitBtn
                    variant="contained"
                    startIcon={<SearchIcon />}
                    submitting={loading}
                    pristine={pristine && !modifiedSinceLastSubmit}
                  >
                    ค้นหา
                  </SubmitBtn>
                  <Button
                    variant="outlined"
                    disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                    onClick={(event) => {
                      form.reset();
                      if (onReset) onReset(event);
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
}
