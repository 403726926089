import { Paper, Skeleton, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../apis";
import { personalForceReload } from "../../../ducks/personalList";
import PersonalForm, { validate, validateRules } from "./PersonalForm";
// import { forceReload as reloadAccountList } from "../../../ducks/accountList";
// import { forceReload as reloadTransactionList } from "../../../ducks/transactionSearch";

const mapToInit = (item) => {
  return _.pick(item, Object.keys(validateRules));
};

export default function PersonalEditPage({ personalId, handleCancel }) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const dispatch = useDispatch();
  const [nationality, setNationality] = useState(1); // 1 = คนไทย 2 = คนต่างชาติ

  const onSubmit = (formValues) => {
    // console.log(formValues);
    const _nationality = { nationality: nationality };
    const objData = Object.assign({}, formValues, _nationality);
    return api
      .put(`/api/cases/${caseId}/persons/${personalId}`, objData)
      .then(({ data, status }) => {
        // if (status === 200) {
        dispatch(personalForceReload());
        handleCancel();
        // }
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.error(errors);
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  const [item, setItem] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    if (item) return undefined;

    api
      .get(`/api/cases/${caseId}/persons/${personalId}`)
      .then(({ data }) => {
        isSubscribed && setItem(data);
      })
      .catch(({ response }) => {
        if (response && response.status === 404) handleCancel();
      });
    return () => {
      isSubscribed = false;
    };
  }, [caseId, personalId, handleCancel, item]);

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        แก้ไขบุคคล
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {!item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={PersonalForm}
            initialValues={mapToInit(item)}
            setNationality={setNationality}
            handleCancel={handleCancel}
          />
        )}
      </Paper>
    </>
  );
}
