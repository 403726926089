// material-ui
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography
} from "@mui/material";

import { Memory, Save, Storage } from "@mui/icons-material";

import MainCard from "./cards/MainCard";
import CPU from "./CPU";
import Disk from "./Disk";
import Machine from "./Machine";
import MemoryPage from "./Memory";
import ShowWindows from "./ShowWindows";
import Traffic from "./Traffic";

export default function Monitor() {
  return (
    <>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant="h5">System Monitoring</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MainCard sx={{ mt: 1 }} content={false}>
            <Box sx={{ p: 3, pb: 2 }}>
              <ShowWindows />
            </Box>
          </MainCard>
        </Grid>
      </Grid>

      <Paper
        sx={{
          mt: 2,
          p: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={8}>
            <Grid container>
              <Grid item xs={12} md={12} lg={6}>
                <MainCard sx={{ mt: 0 }} content={false}>
                  <Box sx={{ p: 3 }}>
                    <Paper>
                      <Grid container spacing={2} mb={2}>
                        <Grid item md={12} lg={8}>
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                            sx={{ ml: 2 }}
                          >
                            <Storage />
                            <Typography variant="body1" color="textSecondary">
                              CPU
                            </Typography>
                            <Typography variant="body1">2.6GHz</Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12} lg={4}>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                            sx={{ mr: 2 }}
                          >
                            <Typography variant="body1">55 'C</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <CPU />
                    </Paper>
                  </Box>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <MainCard sx={{ mt: 0 }} content={false}>
                  <Box sx={{ p: 3 }}>
                    <Paper>
                      <Grid container spacing={2} mb={2}>
                        <Grid item md={12} lg={8}>
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                            sx={{ ml: 2 }}
                          >
                            <Memory />
                            <Typography variant="body1" color="textSecondary">
                              Memory
                            </Typography>
                            <Typography variant="body1">16GB</Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12} lg={4}>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                            sx={{ mr: 2 }}
                          >
                            <Typography variant="body1" color="textSecondary">
                              FREE
                            </Typography>
                            <Typography variant="body1"> 4GB</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <MemoryPage />
                    </Paper>
                  </Box>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <MainCard sx={{ mt: 0 }} content={false}>
                  <Box sx={{ p: 3 }}>
                    <Paper>
                      <Grid container spacing={2} mb={2}>
                        <Grid item md={12} lg={8}>
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                            sx={{ ml: 2 }}
                          >
                            <Save />
                            <Typography variant="body1" color="textSecondary">
                              Disk
                            </Typography>
                            <Typography variant="body1">1TB</Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={12} lg={4}>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                            sx={{ mr: 2 }}
                          >
                            <Typography variant="body1" color="textSecondary">
                              FREE
                            </Typography>
                            <Typography variant="body1">550GB</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Disk />
                    </Paper>
                  </Box>
                </MainCard>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <MainCard sx={{ mt: 0 }} content={false}>
                  <Box sx={{ p: 3 }}>
                    <Paper>
                      <Grid container spacing={2} mb={2}>
                        <Grid item md={12} lg={8}>
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                            sx={{ ml: 2 }}
                          >
                            <Save />
                            <Typography variant="body1" color="textSecondary">
                              NET TRAFFIC
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Traffic />
                    </Paper>
                  </Box>
                </MainCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <MainCard sx={{ mt: 0 }} content={false}>
              <Box sx={{ p: 3 }}>
                <Paper>
                  <Machine />
                </Paper>
              </Box>
            </MainCard>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
