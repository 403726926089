import { Save } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Divider, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import grey from "@mui/material/colors/grey";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import { IOSSwitch } from "../../../form-fields/customs/Switch/IOSSwitch";
import { authFetch } from "../../../../ducks/auth";

export default function PermissionForm({ id }) {
  const qState = useSelector((state) => state.auth.user.permissions);
  const userRoles = useSelector((state) => state.auth.user?.roles ?? {});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [column, setColumn] = useState(null);
  const [checkbox, setCheckbox] = useState(null);
  const [viewDataInDepartmentCheckBox, setViewDataInDepartmentCheckBox] =
    useState(false);
  const [roleDefaultCheckBox, setRoleDefaultCheckBox] = useState(false);
  const [assignTaskToOtherCheckBox, setAssignTaskToOtherCheckBox] =
    useState(false);

  const [actions, setActions] = useState([]);
  const [checkedActions, setCheckedActions] = useState([]);
  const didRequest = useRef(false);
  const {
    // isLoading,
    // isError,
    data: roles,
  } = useQuery(
    ["roles"],
    () => api.get(`/api/role_template`).then((res) => res.data)
    // { staleTime: 60 * 1000 }
  );

  // console.log(roles);

  const {
    // isLoading,
    // isError,
    data: role_by_id,
  } = useQuery(["role_by_id", id], () =>
    api.get(`/api/roles/${id}`).then((res) => res.data)
  );

  const {
    // isLoading,
    // isError,
    data: role_by_id_template,
  } = useQuery(["role_by_id_template", id], () =>
    api.get(`/api/roles/${id}/template`).then((res) => res.data)
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    const { name, description, enable } = data;
    const permissions = [];

    _.map(_.get(roles, "rows", []), (row) =>
      _.map(_.get(row, "list", []), (list) => {
        let checked_actions = [];
        _.map(_.get(roles, "actions", []), (action) => {
          if (data.permissions[list.slug][action.name])
            checked_actions.push(action.name);
        });
        permissions.push({
          slug: list.slug,
          checked_actions,
        });

        _.map(_.get(list, "items", []), (item) => {
          let checked_actions2 = [];
          _.map(_.get(roles, "actions", []), (action) => {
            if (data.permissions[list.slug][item.slug][action.name])
              checked_actions2.push(action.name);
          });
          permissions.push({
            slug: item.slug,
            checked_actions: checked_actions2,
          });
        });
      })
    );

    const formData = {
      name,
      description,
      enable,
      permissions,
      cases_only_viewer_org: viewDataInDepartmentCheckBox ? 1 : 0,
      role_default: roleDefaultCheckBox ? 1 : 0,
      assign_cases_external_org: assignTaskToOtherCheckBox ? 1 : 0,
    };
    onEditData(formData);
  };

  const onEditData = (body) => {
    const fetchData = async () => {
      const response = await api.put(`api/roles/${id}`, body).then((res) => {
        if (Object.keys(userRoles).find((key) => userRoles[key].id === id))
          dispatch(authFetch());
        navigate("/settingpermission");
        return res;
      });
      return response;
    };
    const callFunction = fetchData();
    toast.promise(callFunction, {
      loading: <b>กำลังโหลด...</b>,
      success: <b>แก้ไขข้อมูลเสร็จสิ้น</b>,
      error: <b>ระบบเกิดความผิดพลาด กรุณาแจ้งเจ้าหน้าที่</b>,
    });
  };

  useEffect(() => {
    // console.log({ role_by_id });
    if (role_by_id) {
      reset();
      setValue("name", role_by_id.name, "");
      setValue("description", role_by_id.description, "");
      setValue("enable", String(role_by_id.enable), "0");
      setViewDataInDepartmentCheckBox(role_by_id.cases_only_viewer_org === 1);
      setRoleDefaultCheckBox(role_by_id.role_default === 1);
      setAssignTaskToOtherCheckBox(role_by_id.assign_cases_external_org === 1);

      _.map(_.get(role_by_id_template, "rows", []), (row) => {
        _.map(_.get(row, "list", []), (list) => {
          _.map(_.get(list, "checked_actions", []), (action) => {
            //Topic
            setValue(`permissions.${list.slug}.${action}`, true);
          });

          _.map(_.get(list, "items", []), (item) => {
            _.map(_.get(item, "checked_actions", []), (action) => {
              setValue(`permissions.${list.slug}.${item.slug}.${action}`, true);
            });
          });
        });
      });
    }
  }, [reset, role_by_id, role_by_id_template, setValue]);

  useEffect(() => {
    if (actions.length > 0 && role_by_id_template) {
      _.map(_.get(role_by_id_template, "actions", []), (el) => {
        setValue(
          `column.${_.get(el, "name")}`,
          _.filter(actions, (it) => it === _.get(el, "name")).length ===
            _.filter(checkedActions, (it) => it === _.get(el, "name")).length
        );
      });
      didRequest.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, role_by_id_template, setValue]);

  useEffect(() => {
    _.map(_.get(roles, "rows", []), (group) =>
      _.map(_.get(group, "list", []), (list) => {
        _.map(_.get(roles, "actions", []), (action) => {
          if (
            column === action.name &&
            list.available_actions.includes(action.name)
          )
            setValue(
              `permissions.${list.slug}.${action.name}`,
              watch(`column.${action.name}`)
            );
          _.map(_.get(list, "items", []), (item) => {
            if (
              column === action.name &&
              (list.available_actions.includes(action.name) ||
                item.available_actions.includes(action.name))
            )
              setValue(
                `permissions.${list.slug}.${item.slug}.${action.name}`,
                watch(`column.${action.name}`)
              );
          });
        });
      })
    );
    setColumn(null);
  }, [column, roles, setValue, watch]);

  useEffect(() => {
    _.map(_.get(roles, "rows", []), (group) =>
      _.map(
        _.filter(_.get(group, "list", []), (val) => val.slug === checkbox),
        (list) => {
          _.map(_.get(roles, "actions", []), (action) => {
            // setValue(
            //   `permissions.${list.slug}.${action.name}`,
            //   watch(`column.${action.name}`)
            // );

            _.map(_.get(list, "items", []), (item) => {
              setValue(
                `permissions.${list.slug}.${item.slug}.list`,
                watch(`permissions.${list.slug}.list`)
              );
            });
          });
        }
      )
    );
    setCheckbox(null);
  }, [checkbox, roles, setValue, watch]);
  useEffect(() => {
    setActions([]);
    if (roles)
      _.map(_.get(roles, "rows", []), (el) =>
        _.map(_.get(el, "list", []), (list) =>
          _.map(_.get(roles, "actions", []), (it) => {
            _.get(list, "available_actions", []).includes(_.get(it, "name")) &&
              setActions((prev) => [...prev, _.get(it, "name")]);
            _.map(
              _.get(list, "items", []),
              (sub_list) =>
                _.get(sub_list, "available_actions", []).includes(
                  _.get(it, "name")
                ) && setActions((prev) => [...prev, _.get(it, "name")])
            );
          })
        )
      );
  }, [roles]);
  useEffect(() => {
    setCheckedActions([]);
    if (role_by_id_template)
      _.map(_.get(role_by_id_template, "rows", []), (el) =>
        _.map(_.get(el, "list", []), (list) =>
          _.map(_.get(role_by_id_template, "actions", []), (it) => {
            _.get(list, "checked_actions", []).includes(_.get(it, "name")) &&
              setCheckedActions((prev) => [...prev, _.get(it, "name")]);
            _.map(
              _.get(list, "items", []),
              (sub_list) =>
                _.get(sub_list, "checked_actions", []).includes(
                  _.get(it, "name")
                ) && setCheckedActions((prev) => [...prev, _.get(it, "name")])
            );
          })
        )
      );
  }, [role_by_id_template]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-3 justify-center items-center">
          <div>
            <Controller
              name="name"
              rules={{
                required: {
                  value: true,
                  message: "กรุณากรอกชื่อสิทธิ์การใช้งาน",
                },
              }}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  InputLabelProps={{ shrink: true }}
                  error={_.get(errors, "name", "")}
                  sx={{ width: "100%" }}
                  variant="outlined"
                  label="ชื่อสิทธิ์การใช้งาน*"
                />
              )}
            />
            {_.get(errors, "name", "") && (
              <p className="text-red-500 text-sm">
                {_.get(errors, "name.message", "")}
              </p>
            )}
          </div>
        </div>
        <div className="mt-5">
          <Controller
            name="description"
            control={control}
            rules={{
              required: {
                value: true,
                message: "กรุณากรอกรายละเอียด",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={_.get(errors, "description", "")}
                InputLabelProps={{ shrink: true }}
                sx={{ width: "100%" }}
                variant="outlined"
                label="รายละเอียด"
              />
            )}
          />
          {_.get(errors, "description", "") && (
            <p className="text-red-500 text-sm">
              {_.get(errors, "description.message", "")}
            </p>
          )}
        </div>
        <div className="mt-5">
          <div className="flex item-center gap-3 mt-1">
            <div className="flex items-center">
              <input
                {...register("enable")}
                type="radio"
                value="1"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label className="ms-2 text-sm font-medium text-gray-900">
                ใช้งาน
              </label>
            </div>
            <div className="flex items-center">
              <input
                {...register("enable")}
                type="radio"
                value="0"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
              />
              <label className="ms-2 text-sm font-medium text-gray-900 ">
                ไม่ใช้งาน
              </label>
            </div>
          </div>
        </div>
        <Divider sx={{ mt: 3 }} />
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: grey[100] }}
          >
            <Box
              sx={{
                marginLeft: 2,
              }}
            >
              <Typography variant="h6">สิทธิ์ การใช้งาน</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className="p-3 overflow-x-scroll w-full ">
              <div className="grid grid-cols-7">
                <div className="overflow-y-auto px-2 col-span-2" />

                <div className="flex justify-start w-full col-span-5">
                  {_.map(_.get(roles, "actions", []), (item, i) => (
                    <div
                      key={_.get(item, "order")}
                      className="px-2 py-1 text-xs justify-center items-center "
                    >
                      <div className="text-center w-14 truncate">
                        {_.get(item, "label", "")}
                      </div>
                      <div className="flex items-center justify-center mt-1">
                        <input
                          {...register(`column.${_.get(item, "name")}`)}
                          id={`column-${i}`}
                          onClick={() => setColumn(_.get(item, "name"))}
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                          defaultChecked={
                            _.filter(
                              actions,
                              (it) => it === _.get(item, "name")
                            ).length ===
                            _.filter(
                              checkedActions,
                              (it) => it === _.get(item, "name")
                            ).length
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                {_.map(_.get(roles, "rows", []), (item, i) => (
                  <div className="mt-5" key={i}>
                    <div className="grid grid-cols-7">
                      <div className=" px-2 col-span-2 grid">
                        <div>
                          <p className=" text-xl  mt-1 text-gray-900 font-medium">
                            {_.get(item, "group_name")}
                          </p>
                        </div>
                      </div>
                      <div className="col-span-5 gap-3"></div>
                    </div>
                    {_.map(_.get(item, "list", []), (list, l) => (
                      <div key={_.get(list, "slug")}>
                        <div className="grid grid-cols-7">
                          <div className="px-2 col-span-2 grid">
                            <div>
                              <p className="text-2xs font-medium mt-2 ml-5">
                                {_.get(list, "name")}
                              </p>
                            </div>
                          </div>
                          <div className="w-full col-span-5 gap-3">
                            <div className="flex justify-start">
                              {_.map(_.get(roles, "actions", []), (item) => (
                                <div
                                  key={_.get(item, "order")}
                                  className="px-2 py-1 text-xs justify-center items-center"
                                >
                                  <div className="text-center w-14 truncate" />
                                  <div className="flex items-center justify-center mt-1">
                                    {_.get(
                                      list,
                                      "available_actions",
                                      []
                                    ).includes(_.get(item, "name")) && (
                                      <input
                                        {...register(
                                          `permissions.${_.get(
                                            list,
                                            "slug"
                                          )}.${_.get(item, "name")}`
                                        )}
                                        type="checkbox"
                                        onClick={() =>
                                          setCheckbox(_.get(list, "slug"))
                                        }
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        {_.map(_.get(list, "items", []), (sub_list, sl) => (
                          <div key={sl} className="grid grid-cols-7">
                            <div className="px-2 col-span-2 grid">
                              <div>
                                <p className="text-2xs mt-2 ml-10">
                                  {
                                    {
                                      status: (
                                        <span className="text-gray-400">
                                          สถานะ :{" "}
                                        </span>
                                      ),
                                      menu: <span> - </span>,
                                    }[_.get(sub_list, "type")]
                                  }
                                  {_.get(sub_list, "name")}
                                </p>
                              </div>
                            </div>
                            <div className="w-full col-span-5 gap-3">
                              <div className="flex justify-start">
                                {_.map(_.get(roles, "actions", []), (item) => (
                                  <div
                                    key={_.get(item, "order")}
                                    className="px-2 py-1 text-xs justify-center items-center "
                                  >
                                    <div className="text-center w-14 truncate" />
                                    <div className="flex items-center justify-center mt-1">
                                      {_.get(
                                        sub_list,
                                        "available_actions",
                                        []
                                      ).includes(_.get(item, "name")) && (
                                        <input
                                          {...register(
                                            `permissions.${_.get(
                                              list,
                                              "slug"
                                            )}.${_.get(
                                              sub_list,
                                              "slug"
                                            )}.${_.get(item, "name")}`
                                          )}
                                          type="checkbox"
                                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                        />
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: grey[100] }}
          >
            <Box sx={{ marginLeft: 2 }}>
              <Typography variant="h6">อื่นๆ</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: "50%",
                marginLeft: 2,
                paddingY: 4,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                gap={2}
                alignItems="center"
                mb={2}
              >
                <span>การมองเห็นข้อมูล ภายในหน่วยงานที่รับผิดชอบ</span>
                <IOSSwitch
                  checked={viewDataInDepartmentCheckBox}
                  onClick={() => {
                    setViewDataInDepartmentCheckBox(
                      !viewDataInDepartmentCheckBox
                    );
                  }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap={2}
                alignItems="center"
                mb={2}
              >
                <span>
                  ค่าตั้งต้นสิทธิ์ของผู้สมัครสมาชิกใหม่ (Role Default)
                </span>
                <IOSSwitch
                  checked={roleDefaultCheckBox}
                  onClick={(e) => {
                    setRoleDefaultCheckBox(!roleDefaultCheckBox);
                  }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap={2}
                alignItems="center"
                mb={2}
              >
                <span>มอบหมายงาน ให้คนนอกในหน่วยงานตามลำดับขั้นอันอื่น</span>
                <IOSSwitch
                  checked={assignTaskToOtherCheckBox}
                  onClick={(e) => {
                    setAssignTaskToOtherCheckBox(!assignTaskToOtherCheckBox);
                  }}
                />
              </Box>
            </Box>

            {!qState?.setting_permission?.includes("edit") && (
              <p className="text-red-400 my-2">คุณไม่มีสิทธิ์แก้ไข</p>
            )}
            <div className="mt-5 flex justify-start items-center gap-5">
              <Button
                type="submit"
                variant="contained"
                disabled={!qState?.setting_permission?.includes("edit")}
              >
                <Save fontSize="18px" />
                <span className="ml-1"> ตกลง</span>
              </Button>
              <Button
                onClick={() => navigate(-1)}
                type="button"
                variant="outlined"
              >
                ยกเลิก
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* <Divider sx={{ mt: 3 }} />
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ backgroundColor: grey[100] }}
          >
            <Typography>สิทธิ์ การใช้งาน</Typography>
          </AccordionSummary>
          <AccordionDetails>
          <div className="p-3 overflow-x-scroll w-full ">
              <div className="grid grid-cols-7">
                <div className="overflow-y-auto px-2 col-span-2" />

                <div className="flex justify-start w-full col-span-5">
                  {_.map(_.get(roles, "actions", []), (item, i) => (
                    <div
                      key={_.get(item, "order")}
                      className="px-2 py-1 text-xs justify-center items-center "
                    >
                      <div className="text-center w-14 truncate">
                        {_.get(item, "name", "")}
                      </div>
                      <div className="flex items-center justify-center mt-1">
                        <input
                          {...register(`column.${_.get(item, "name")}`)}
                          id={`column-${i}`}
                          onClick={() => setColumn(_.get(item, "name"))}
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                {_.map(_.get(roles, "rows", []), (item, i) => (
                  <div className="mt-5" key={i}>
                    <div className="grid grid-cols-7">
                      <div className=" px-2 col-span-2 grid">
                        <div>
                          <p className="text-lg mt-1 text-gray-900 font-medium">
                            {_.get(item, "group_name")}
                          </p>
                        </div>
                      </div>
                      <div className="col-span-5 gap-3"></div>
                    </div>
                    {_.map(_.get(item, "list", []), (list, l) => (
                      <div key={_.get(list, "slug")}>
                        <div className="grid grid-cols-7">
                          <div className="px-2 col-span-2 grid">
                            <div>
                              <p className="text-xs mt-2 ml-5">
                                {_.get(list, "name")}
                              </p>
                            </div>
                          </div>
                          <div className="w-full col-span-5 gap-3">
                            <div className="flex justify-start">
                              {_.map(_.get(roles, "actions", []), (item) => (
                                <div
                                  key={_.get(item, "order")}
                                  className="px-2 py-1 text-xs justify-center items-center"
                                >
                                  <div className="text-center w-14 truncate" />
                                  <div className="flex items-center justify-center mt-1">
                                    {_.get(
                                      list,
                                      "available_actions",
                                      []
                                    ).includes(_.get(item, "name")) && (
                                      <input
                                        {...register(
                                          `permissions.${_.get(
                                            list,
                                            "slug"
                                          )}.${_.get(item, "name")}`
                                        )}
                                        type="checkbox"
                                        onClick={() =>
                                          setCheckbox(_.get(list, "slug"))
                                        }
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        {_.map(_.get(list, "items", []), (sub_list, sl) => (
                          <div key={sl} className="grid grid-cols-7">
                            <div className="px-2 col-span-2 grid">
                              <div>
                                <p className="text-xs mt-2 ml-10">
                                  {
                                    {
                                      status: (
                                        <span className="text-gray-400">
                                          สถานะ :{" "}
                                        </span>
                                      ),
                                      menu: <span> - </span>,
                                    }[_.get(sub_list, "type")]
                                  }
                                  {_.get(sub_list, "name")}
                                </p>
                              </div>
                            </div>
                            <div className="w-full col-span-5 gap-3">
                              <div className="flex justify-start">
                                {_.map(_.get(roles, "actions", []), (item) => (
                                  <div
                                    key={_.get(item, "order")}
                                    className="px-2 py-1 text-xs justify-center items-center "
                                  >
                                    <div className="text-center w-14 truncate" />
                                    <div className="flex items-center justify-center mt-1">
                                      {_.get(
                                        sub_list,
                                        "available_actions",
                                        []
                                      ).includes(_.get(item, "name")) && (
                                        
                                        <input
                                          {...register(
                                            `permissions.${_.get(
                                              list,
                                              "slug"
                                            )}.${_.get(
                                              sub_list,
                                              "slug"
                                            )}.${_.get(item, "name")}`
                                          )}
                                          type="checkbox"
                                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                        />
                                      
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            
             </AccordionDetails>
      </Accordion>
       
        <Divider sx={{ mt: 3 }} />
        <div className="mt-5 flex justify-start items-center gap-5">
          <Button type="submit" variant="contained">
            <Save fontSize="18px" />
            <span className="ml-1"> ตกลง</span>
          </Button>
          <Button onClick={() => navigate(-1)} type="button" variant="outlined">
            ยกเลิก
          </Button>
        </div> */}
      </form>
    </div>
  );
}

export const mapToInit = (item) => {
  const data = item !== undefined ? item[0] : [];
  return data;
};
