import {
  Box,
  Button,
  Chip,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React ,{useEffect} from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import PersonalHistoryForm from "./PersonalHistoryForm";
import { useQuery } from "react-query";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import { format, parseISO } from "date-fns";


export default function PersonalHistory({
  handleSubmit,
  handleCancel,
  data
}) {
  // const params = useParams();
  // const caseId = parseInt(params.id);

  const [pageSize, setPageSize] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const rows = useSelector((state) => state.personalList?.telephone?.rows);
  const loading = useSelector(
    (state) => state.personalList.metaTelephone.loading
  );

  

  
  const { data: personal } = useQuery(
    ["personalHistory",data],
    () => 
      api.get(`/api/cases/${data?.case_id}/bank_accounts/${data?.id}`).then((res) => res.data),
    { staleTime: 60 * 1000 ,
    }
  );

  useEffect(() => {
    console.log("dadada",data)
  }, [data])
  


  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography>{params?.row?.id}</Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      minWidth: 120,
      maxWidth: 120,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      minWidth: 100,
      maxWidth: 100,
      renderCell: (paramse) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography>{paramse?.row?.detail_id}</Typography>
          </Box>
        );
      },
    },
    {
      field: "equest_type",
      headerName: "ประเภทคำขอ",
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Typography>{params?.row?.request_type?.name}</Typography>
          </Box>
        );
      },
    },
    {
      field: "requestdate",
      headerName: "วันที่ขอ",
      minWidth: 150,
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Typography>{format(parseISO(params?.row?.created_at), "dd/MM/yyyy HH:mm:ss")}</Typography>
          </Box>
        );
      },
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      minWidth: 200,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Typography>{params?.row?.request_type?.description ? params?.row?.request_type?.description : "-" }</Typography>
          </Box>
        );
      },
    },
    {
      field: "status_note",
      headerName: "หมายเหตุ",
      minWidth: 150,
      maxWidth: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Typography>{params?.row?.status_note ?  params?.row?.status_note : "-"}</Typography>
          </Box>
        );
      },

    },
  ];

  const onSubmit = (formValues) => {
    // return api
    //   .post(`/api/`, formValues)
    //   .then(({ data }) => {})
    //   .catch(({ response }) => {
    //     if (response.status === 422) {
    //       const errors = _.mapValues(response.data.errors, (e) => e[0]);
    //     } else {
    //       return {
    //         [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
    //       };
    //     }
    //   });
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        ข้อมูลบุคคล
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form
          onSubmit={onSubmit}
          component={PersonalHistoryForm}
          handleCloseModalHistory={handleCancel}
          data={personal}
        />
          <Box sx={{ height: 400,width:'100%'}}>
          <DataGrid
            disableColumnMenu
            columns={columns}
            rows={personal?.list_request}
            loading={loading}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            // pagination
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(page) => setPage(page)}
          />
        </Box>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button variant="outlined" onClick={handleCancel}>
            ปิด
          </Button>
        </Stack>
      </Paper>
    </>
  );
}
