import axios from "axios";
import LocalStorageService from "../helpers/LocalStorageService";
import { authLogout } from "../ducks/auth";

let store = null;
const apiConfig = {
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_PROD_URL
      : process.env.REACT_APP_API_DEV_URL,
  // baseURL: window.location.origin + (process.env.REACT_APP_BASE_URL || ""),
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "ngrok-skip-browser-warning": null,
  },
};
const api = axios.create(apiConfig);
// refresh api is not need interceptors
const refreshApi = axios.create(apiConfig);

export default api;
// api use for doing authorization request
// ( no need auto refresh response interceptor which may cuase infinite request.)
export const authApi = refreshApi;

export const g9initApi = (reduxStore) => {
  store = reduxStore;
  const accessToken = LocalStorageService.getIaccessToken();
  if (accessToken) setAuthorizationToken(accessToken);
};

let isRefreshing = false;
let refreshSubscribers = [];

// // Add a request interceptor
// api.interceptors.request.use(
//   (config) => {
//     const token = LocalStorageService.getIaccessToken();
//     if (token) {
//       config.headers["Authorization"] = "Bearer " + token;
//     }
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// Add a response interceptor to handle token refreshing
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const statusCode = error.response.status;

    if (statusCode === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          subscribeTokenRefresh((token) => {
            if (token) {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              resolve(axios(originalRequest));
            } else {
              reject(error);
            }
          });
        });
      }

      isRefreshing = true;

      const refreshToken = LocalStorageService.getIrefreshToken();
      const username = LocalStorageService.getUsername();

      return new Promise((resolve, reject) => {
        if (!refreshToken || !username) {
          // no need to waste time asking for refresh
          store.dispatch(authLogout());
          reject(error);
          processSubscribers();
          return;
        }

        // Make a request to refresh the access token using the refresh token
        refreshApi
          .post("/api/irefresh", { refresh_token: refreshToken, username })
          .then((response) => {
            const newAccessToken = response.data.access_token;

            // Update the access token and refresh token in your storage
            LocalStorageService.setItoken(response.data);
            setAuthorizationToken(LocalStorageService.getIaccessToken());

            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

            resolve(axios(originalRequest));
            processSubscribers(newAccessToken);
          })
          .catch((error) => {
            // Handle refresh token request failure (e.g., logout the user)
            store.dispatch(authLogout());
            reject(error);
            processSubscribers();
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

function processSubscribers(token) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

export const setAuthorizationToken = (token) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};
