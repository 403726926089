import PieChartIcon from "@mui/icons-material/PieChart";
import { Grid, Typography } from "@mui/material";

import MainCard from "./cards/MainCard";
import AnalyticsCase from "./cards/statistics/AnalyticsCase";
import OrdersTableCase from "./OrdersTableCase";
import OrdersTableRequest from "./OrdersTableRequest";
import OrdersTableUse from "./OrdersTableUse";
import SalesColumnChart from "./SalesColumnChart";

export default function Dashboard() {
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Dashboard (แดชบอร์ด)</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h6">สถิติข้อมูลรายการเคส</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.4}>
        <AnalyticsCase
          title="จำนวนทั้งหมด"
          count="43,563"
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.4}>
        <AnalyticsCase
          title="เคสมีผู้รับผิดชอบ"
          count="43,563"
          percentage={70.5}
          extra="8,900"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.4}>
        <AnalyticsCase
          title="เคสขาดผู้รับผิดชอบ"
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.4}>
        <AnalyticsCase
          title="ได้รับมอบหมายเคส"
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2.4}>
        <AnalyticsCase
          title="เคสที่สร้างด้วย พงส."
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <MainCard sx={{ mt: 2 }} content={false}>
          <OrdersTableCase />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <MainCard sx={{ mt: 2 }} content={false}>
          <OrdersTableRequest />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <MainCard sx={{ mt: 2 }} content={false}>
          <OrdersTableUse />
        </MainCard>
      </Grid>

      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h6">สถิติข้อมูลหมายคำขอ (ตามสถานะ)</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticsCase
          title="จำนวนทั้งหมด"
          count="43,563"
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticsCase
          title="เคสมีผู้รับผิดชอบ"
          count="43,563"
          percentage={70.5}
          extra="8,900"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสขาดผู้รับผิดชอบ"
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="ได้รับมอบหมายเคส"
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสที่สร้างด้วย พงส."
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>

      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h6">สถิติการขอข้อมูลบัญชีธนาคาร</Typography>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="body2">
          <PieChartIcon />
          แผนภูมิแสดงการขอเปิดบัญชี
        </Typography>
        <MainCard content={false}>
          <SalesColumnChart />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="body2">
          <PieChartIcon />
          แผนภูมิแสดงการขอรายการเดินบัญชี
        </Typography>
        <MainCard content={false}>
          <SalesColumnChart />
        </MainCard>
      </Grid>

      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h6">สถิติ HR03 (รายการ)</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="จำนวนทั้งหมด"
          count="43,563"
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสมีผู้รับผิดชอบ"
          count="917"
          percentage={70.5}
          extra="8,900"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสขาดผู้รับผิดชอบ"
          count="7,073"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="ได้รับมอบหมายเคส"
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสที่สร้างด้วย พงส."
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสที่สร้างด้วย พงส."
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h6">สถิติ HR03 (รายคน)</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="จำนวนทั้งหมด"
          count="43,563"
          percentage={59.3}
          color="error"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสมีผู้รับผิดชอบ"
          count="43,563"
          percentage={70.5}
          extra="8,900"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสมีผู้รับผิดชอบ"
          count="43,563"
          percentage={70.5}
          extra="8,900"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสขาดผู้รับผิดชอบ"
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="ได้รับมอบหมายเคส"
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <AnalyticsCase
          title="เคสที่สร้างด้วย พงส."
          count="43,563"
          percentage={27.4}
          isLoss
          color="warning"
        />
      </Grid>
    </Grid>
  );
}
