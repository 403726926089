import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React, { useEffect } from "react";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";
import { Alert, Button, Divider, Grid, Stack } from "@mui/material";
import FieldInput from "../../form-fields/FieldInput";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import SubmitBtn from "../../SubmitBtn";
import { Save } from "@mui/icons-material";
import bankAccountTypes from "../../../data/selects/bankAccountTypes";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import { getTextMask } from "../../../helpers/imask";
import countryOptions from "../../../data/selects/countries";

const TextMaskCustom = getTextMask({
  mask: "000000000000",
  unmask: true,
  overwrite: true,
});

export default function PersonalHistoryForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  data,
}) {
  useEffect(() => {
    if (data) {
      // form.change("bank", `${data?.bank.name} (${data?.bank.short_name})`);
      // form.change("banknumber", data?.number);
      // form.change("bankName", data?.name);
      // form.change("type", data?.type.type);
      // form.change("priority", data?.seq);
      // form.change("status", data?.freeze_status);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FieldInput
            name="bank"
            label="ประเภทบุคคล"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="banknumber"
            label="หมายเลขบัตร/เลขหนังสือเดินทาง"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="bankName"
            label="ชื่อ-สกุล"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="type"
            label="สัญชาติ/ประเทศ"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="priority"
            label="ประเภทบัญชี"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="status"
            label="สถานะอายัด"
            controlProps={{ fullWidth: true }}
            readOnly={true}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </form>
  );
}
