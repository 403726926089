import _ from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resendOTP } from "../../ducks/resendOTP";
import { verifyOTP } from "../../ducks/verifyOTP";
import { Link } from "@mui/material";

export default function TwoFactorPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [otp, setOtp] = React.useState("");
  const [refno, setRefno] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [time, setTime] = useState(60);

  useEffect(() => {
    if (location) {
      setEmail(_.get(location, "state.user.email"));
      setToken(_.get(location, "state.token.token"));
      setRefno(_.get(location, "state.token.refno"));
    }
  }, [location]);

  const onSubmit = () => {
    // if (otp.length < 4) addToast("กรุณาใส่ตัวเลขให้ครบ 4 ช่อง", { appearance: 'error' });
    // console.log({ location });

    // console.log({ token ,email});
    return dispatch(
      verifyOTP({
        email,
        // password: location.state.user.password,
        token,
        otp,
      })
    ).then((data) => {
      if (data) {
        toast.success("ยืนยันตัวตนเสร็จสิ้น");
        navigate("/me");
      } else toast.error("รหัส OTP ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
    });
  };

  const onResendOTP = () => {
    return dispatch(
      resendOTP({
        email,
      })
    )
      .then((data) => {
        setTime(60);
        setRefno(_.get(data, "data.refno"));
        setToken(_.get(data, "data.token"));
        toast.success("ส่งรหัส OTP อีกครั้งแล้ว");
      })
      .catch((err) => {
        toast.error("ขอ OTP ไม่สำเร็จ");
      });
  };

  useEffect(() => {
    otp.length === 4 && onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  useEffect(() => {
    let interval = null;

    if (time > 0) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time]);

  // useEffect(() => {
  //   return dispatch(
  //     init({
  //       access_token: location.state.token.access_token,
  //       token_type: location.state.token.access_token,
  //       // email: location.state.user.email,
  //       // password: location.state.user.password,
  //     })
  //   )
  //     .then((data) => {
  //       setRefno(_.get(data, "refno", ""));
  //       setToken(_.get(data, "token", ""));
  //       // navigate(from, { replace: true });
  //     })
  //     .catch(({ response }) => {
  //       if (response.status === 429) {
  //         return {
  //           [FORM_ERROR]:
  //             "พบการเรียกใช้งานระบบเป็นจำนวนมาก กรุณากลับมาใช้งานใหม่ในอีก 5 นาที",
  //         };
  //       } else if (response.status >= 400 && response.status < 500) {
  //         return {
  //           [FORM_ERROR]: "email หรือ password ไม่ถูกต้อง",
  //         };
  //       } else {
  //         return {
  //           [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
  //         };
  //       }
  //     });
  // });

  const onCancel = () => navigate("/login");

  return (
    <div className="bg-[#F5F5F5] h-screen grid justify-center items-center ">
      {/* <CssBaseline /> */}
      <div className="bg-white rounded-[32px] py-8 px-16 relative mx-4 shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-16 p-1">
          <div className="h-24 w-24 rounded-full bg-[#E8FFEE] p-4">
            <img src="/images/two-factor-icon.svg" alt="" />
          </div>
        </div>
        <div>
          <h2 className="text-xl font-medium text-center">
            Two-Factor Authentication
          </h2>
          <p className="mt-3">Please enter the authentication code from SMS.</p>
          <div className="flex justify-between">
            <div>
              <small className="text-xs mr-2">
                Resend available in {time} seconds
              </small>
              {time === 0 && (
                <Link
                  onClick={() => onResendOTP()}
                  className="text-xs ml-1 text-[#0395FF] cursor-pointer"
                >
                  Resend
                </Link>
              )}
            </div>
            <div>
              <small className="text-xs">(Ref. {refno})</small>
            </div>
          </div>
        </div>
        <div className="mt-7">
          <OtpInput
            placeholder="****"
            value={otp}
            onChange={setOtp}
            containerStyle={{
              justifyContent: "center",
            }}
            renderSeparator={
              <span
                style={{
                  // fontSize: "7px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
              ></span>
            }
            shouldAutoFocus
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "50px",
              marginTop: "0px",
              marginBottom: "0px",
              height: "50px",
              // borderTop: "none",
              // borderLeft: "none",
              // borderRight: "none",
              // backgroundColor: "transparent",
              // outline: "none",
              borderRadius: "8px",
              fontSize: 30,
              fontFamily: "Kanit",
              borderWidth: "1px",
              borderColor: "#000",
              textAlign: "center",
            }}
          />
        </div>
        <div className="flex justify-around items-center gap-12 mt-7">
          <button
            onClick={onCancel}
            className="bg-white border border-[#0395FF] py-2 w-full rounded-md text-[#0395FF]"
          >
            Cancle
          </button>
          <button
            onClick={onSubmit}
            className=" border bg-[#0395FF] py-2 w-full rounded-md text-white"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
}
