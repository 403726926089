import { ForwardToInbox, Refresh, Visibility } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useParams, Link } from "react-router-dom";
import { getChip } from "../../../data/requestStatus";
import {
  mrqChangePage,
  mrqChangePageSize,
  mrqClearResult,
  mrqFetch,
  mrqForceReload,
  mrqSetFilter,
} from "../../../ducks/mgtRequestList";
import CheckedRequestView from "./CheckedRequestView";
import NewRequestView from "./NewRequestView";
import OperatorSearchForm, { validate } from "./OperatorSearchForm";
import RequestNewView from "./RequestNewView";
import RequestView from "./RequestView";

export default function RequestListPage({
  isPersonalPage = false,
  isAdminPage = false,
}) {
  const location = useLocation();

  const requests = useSelector(
    (state) => state.auth.user.permissions.requests ?? []
  );
  const qState = useSelector((state) => state.auth.user.permissions);

  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(mrqClearResult());
      dispatch(mrqSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [searchForm, setSearchForm] = useState({});

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.mgtRequestList.data);
  const total = useSelector((state) => state.mgtRequestList.meta.total);
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);
  const forceReload = useSelector(
    (state) => state.mgtRequestList.meta.forceReload
  );
  const page = useSelector((state) => state.mgtRequestList.meta.page);
  const pageSize = useSelector((state) => state.mgtRequestList.meta.pageSize);
  const filter = useSelector((state) => state.mgtRequestList.meta.filter);
  const [reload, setReload] = useState(0);

  // const [rowsState, setRowsState] = React.useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(
      mrqFetch(
        pageSize,
        page,
        activeFilter,
        fetchId,
        fetchIdRef,
        location?.pathname?.includes("admin") ? true : false
      )
    ).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter, reload]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(mrqForceReload());
        }
      }
      setReload(reload + 1);
      setOpenModal(false);
    },
    [dispatch, reload]
  );

  const renderAction = ({ row }) => {
    let modalView = null;

    switch (row.status) {
      case "sent_to_contact_person":
        modalView = (
          <NewRequestView
            handleCancel={handleCloseModal}
            reqId={row.id}
            caseId={row.case_id}
          />
        );
        break;
      case "checked":
        modalView = (
          <CheckedRequestView
            handleCancel={handleCloseModal}
            reqId={row.id}
            caseId={row.case_id}
          />
        );
        break;
      default:
        modalView = (
          <RequestView
            handleCancel={handleCloseModal}
            reqId={row.id}
            caseId={row.case_id}
            setReload={setReload}
          />
        );
    }

    return (
      <Stack
        spacing={1}
        direction="row"
        justifyContent="left"
        sx={{ width: "100%" }}
      >
        {row?.status === "new"  && !location?.pathname?.includes("admin") ? (
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <RequestNewView
                  handleCancel={handleCloseModal}
                  reqId={row?.id}
                  caseId={row?.case_id}
                />
              );
            }}
          >
            <ForwardToInbox color="primary" />
          </IconButton>
        ) : null}

        <IconButton
          type="button"
          variant="contained"
          size="small"
          onClick={() => {
            handleOpenModal(modalView);
          }}
        >
          <Visibility />
        </IconButton>
      </Stack>
    );
  };

  const renderTitle = () => {
    switch (reqStatus) {
      case "sent_to_contact_person":
        return (
          <Stack direction="row" spacing={1}>
            <Typography variant="h5">คำขอเข้าใหม่</Typography>
            {getChip(reqStatus)}
          </Stack>
        );
      case "checked":
        return (
          <Stack direction="row" spacing={1}>
            <Typography variant="h5">คำขอตรวจแล้ว</Typography>
            {getChip(reqStatus)}
          </Stack>
        );
      default:
        return <Typography variant="h5">รายการคำขอทั้งหมด</Typography>;
    }
  };

  const checkPermissionView = useCallback(
    (locationURL) => {
      let type = locationURL.pathname.split("/")[3] ?? "";
      if (type === "sent_to_contact_person") {
        if (qState?.new_request?.includes("view")) {
          return true;
        } else {
          return false;
        }
      } else if (type === "checked") {
        if (qState?.approved_request?.includes("view")) {
          return true;
        } else {
          return false;
        }
      } else {
        if (qState?.all_request?.includes("view")) {
          return true;
        } else {
          return false;
        }
      }
    },
    [qState?.approved_request, qState?.new_request, qState?.all_request]
  );

  const columns = [
    (isPersonalPage && requests.includes("view")) ||
    (isAdminPage && checkPermissionView(location))
      ? {
          field: "actions",
          headerName: "actions",
          sortable: false,
          width: 150,
          renderCell: (params) => {
            return (
              <Stack
                spacing={1}
                direction="row"
                justifyContent="left"
                sx={{ width: "100%" }}
              >
                {isAdminPage &&
                  checkPermissionView(location) &&
                  renderAction(params)}
                {isPersonalPage && requests.includes("view") && (
                  <IconButton
                    component={Link}
                    to={`/cases/${params.row.case_id}/requests/${params.row.id}`}
                    type="button"
                    variant="contained"
                    size="small"
                  >
                    {params.row.status === "new"  ? (
                      <ForwardToInbox color="primary" />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                )}
              </Stack>
            );
          },
        }
      : null,
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "case_id",
      headerName: "Case ID",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}
          </Box>
        );
      },
    },
    {
      field: "case.tpo_case_id",
      headerName: "TPO ID",
      sortable: false,
      maxWidth: 100,
      renderCell: (params) => params.row.case.tpo_case_id,
    },
    {
      field: "detail_id",
      headerName: "setid",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "request_type.name",
      headerName: "ประเภทคำขอ",
      sortable: false,
      minWidth: 280,
      maxWidth: 300,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },
    {
      field: "bank.name",
      headerName: "หน่วยงานภายนอก",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ขอ",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
    {
      field: "description",
      headerName: "บัญชี",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: "detail",
      headerName: "รายละเอียด",
      sortable: false,
      minWidth: 150,
      maxWidth: 200,
      valueGetter: (params) => {
        return params.row.detail?.no;
      },
    },
  ]?.filter(Boolean);

  const onSearchSubmit = (formValues) => {
    setSearchForm(formValues);
    dispatch(mrqSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        {renderTitle()}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSearchSubmit}
            component={OperatorSearchForm}
            loading={loading}
            onReset={() => {
              setSearchForm({});
              dispatch(mrqSetFilter({}));
            }}
            showStatusField={reqStatus ? false : true}
            rows={rows}
            searchForm={searchForm}
            isAdminPage={isAdminPage}
            pageSize={pageSize}
            page={page}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(mrqChangePage(page))}
            onPageSizeChange={(pageSize) =>
              dispatch(mrqChangePageSize(pageSize))
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(mrqForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
