import { Grid, Paper, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from "react-router-dom";
// import RequestTelcoForm from "./request-forms/RequestTelcoForm";
import api from "../../../apis";
import RequestTelcoForm2, {
  mapToInit,
  validate,
} from "./request-forms/RequestTelcoForm2";

export default function RequestTelcoAddPage() {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();
  const [item, setItem] = useState({});

  useEffect(() => {
    let isSubscribed = true;
    api
      .get(`api/me`)
      .then(({ data }) => {
        // console.log(data.permisstions.map((v) => v.option.map((v) => v.info)));
        isSubscribed && setItem(data);
      })
      .catch(({ response }) => {
        // if (response && response.status === 404) {
        // }
        console.error(response);
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onSubmit = async (formData) => {
    if (!formData.profile_signature) {
      return {
        profile_signature: "กรุณาอัพโหลดลายเซ็น",
      };
    }

    let dataTelco = [];
    let dataTelcoA;
    let dataTelcoB;

    if (formData.request_type_9) {
      dataTelcoA = {
        request_type_id: 9,
        data: { ...formData.Telco_A, bank_id: formData.provider },
      };
      dataTelco.push(dataTelcoA);
    }
    if (formData.request_type_10) {
      dataTelcoB = {
        request_type_id: 10,
        data: { ...formData.Telco_B, bank_id: formData.provider },
      };
      dataTelco.push(dataTelcoB);
    }

    const user = {
      rank: formData.rank,
      fname: formData.fname,
      lname: formData.lname,
      job_title: formData.job_title,
      policestation_org_code: formData.policestation_org_code,
      email: formData.email,
      pol_tel: formData.pol_tel,
      address: formData.address,
      profile_signature:
        formData.profile_signature && formData.profile_signature instanceof File
          ? await signatureResize(formData.profile_signature)
          : formData.profile_signature
          ? formData.profile_signature
          : undefined,
    };

    const submitData = { requests: dataTelco, user };

    try {
      const res = await api.post(
        `/api/cases/${caseId}/pol_requests`,
        submitData
      );

      if (res.data.id) {
        navigate(`/cases/${caseId}/requests/${res.data.id}`);
      } else {
        navigate(`/cases/${caseId}/mgt`);
      }
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">สร้างคำขอ Telco (ID: {caseId} )</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={RequestTelcoForm2}
            initialValues={mapToInit(item)}
          />
        </Paper>
      </Grid>
    </>
  );
}

const signatureResize = (file) => {
  const fileType = file.type === "image/png" ? "PNG" : "JPEG";
  const compress = file.type === "image/png" ? 100 : 90;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      90,
      50,
      fileType,
      compress,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};
