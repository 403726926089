import api from "../apis";

export const uploadSuspiciousCreate = (formValues) => async (dispatch) => {
  await api.post(`api/reasonable`, formValues, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  // dispatch({ type: EDIT_ARREST, payload: response.data });
};
