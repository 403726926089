import PropTypes from "prop-types";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import MainCard from "../MainCard";

const AnalyticEcommerce = ({ title, count }) => (
  <MainCard contentSX={{ p: 2.25 }} color="success" variant="soft">
    <Stack spacing={0.5}>
      <Typography variant="h6" color="textSecondary" align="center">
        {title}
      </Typography>
      <Grid>
        <Grid item>
          <Typography variant="h4" color="inherit" align="center">
            {count}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="title" color="inherit" align="center">
        Case
      </Typography>
    </Stack>
    <Box align="center">
      <Typography variant="caption" color="textSecondary">
        <Button size="small" startIcon={<ArrowCircleRightIcon />}>
          ดูเพิ่มเติม
        </Button>
      </Typography>
    </Box>
  </MainCard>
);

AnalyticEcommerce.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  percentage: PropTypes.number,
  isLoss: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

AnalyticEcommerce.defaultProps = {
  color: "primary",
};

export default AnalyticEcommerce;
