import {
  AccessTime,
  AccountCircle,
  Home,
  LocationOn,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  Stack,
  Typography
} from "@mui/material";

const ShowWindows = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Grid item>
              <Typography variant="h4" color="textSecondary">
                WINDOWS-1443
              </Typography>
            </Grid>
          </Stack>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography> Client</Typography>
                <Button size="medium" startIcon={<Home />}>
                  Acme
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography> Site</Typography>
                <Button size="medium" startIcon={<LocationOn />}>
                  West cost
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography> Asset Owner</Typography>
                <Button size="medium" startIcon={<AccountCircle />}>
                  Micheal Scott
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography>Up Time</Typography>
                <Button size="medium" startIcon={<AccessTime />}>
                  5 days 7 hours
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default ShowWindows;
