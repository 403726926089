import {
  Add,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PictureAsPdf,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Snackbar,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import fileDownload from "js-file-download";
import _ from "lodash";
import React, { useEffect, useState ,useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import {
  clearResult,
  fetch,
  markLoading,
  forceReload as rdForceReload,
} from "../../../ducks/accountList";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import AccountAddPage from "./AccountAddPage";
import AccountEditPage from "./AccountEditPage";
import AccountListHistory from "./AccountListHistory";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  minHeight:450,
  height: "80%",
  p: 4,
};

const modalHistoryStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AccountList() {
  const cases = useSelector((state) => state.auth.user.permissions.cases ?? []);
  const params = useParams();
  const caseId = parseInt(params.id);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const dispatch = useDispatch();
  // bank account table
  const fetchIdRef = React.useRef(0);
  const [pageSize, setPageSize] = React.useState(5);

  const rows = useSelector((state) => state.accountList.data);
  // const total = useSelector((state) => state.accountList.meta.total);
  const loading = useSelector((state) => state.accountList.meta.loading);
  // const filter = useSelector((state) => state.accountList.meta.filter);
  const requestColumns = useSelector(
    (state) => state.accountList.meta.requestColumns
  );
  const forceReload = useSelector(
    (state) => state.accountList.meta.forceReload
  );

  // add account modal state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    setOpenModal(false);
  };

  const [openModalHistory, setOpenModalHistory] = React.useState(false);
  const handleOpenModalHistory = (page) => setOpenModalHistory(page);
  const handleCloseModalHistory = useCallback(() => {
    setOpenModalHistory(false);
  }, []);

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/cases/${caseId}/bank_accounts/${cfDel}`)
      .then(() => {
        SetToastInfo({ type: "success", message: "ลบข้อมูลเรียบร้อยแล้ว" });
        dispatch(rdForceReload());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  const _columns = [
    ...(cases.includes("edit") //||cases.includes("delete")
      ? [
          {
            field: "action",
            headerName: "จัดการ",
            sortable: false,
            width: 100,
            renderCell: (params) => {
              return (
                <Stack spacing={0} direction="row">
                  {cases.includes("edit") && (
                    <IconButton
                      type="button"
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenModal(
                          <AccountEditPage
                            accountId={params?.id}
                            handleCancel={handleCloseModal}
                          />
                        );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  {/* {cases.includes("delete") && ( */}
                  <IconButton
                    type="button"
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setCfDel(params.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {/* )} */}
                </Stack>
              );
            },
          },
        ]
      : []),
    {
      field: "bank.name",
      headerName: "ธนาคาร",
      sortable: false,
      width: 100,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "number",
      headerName: "เลขบัญชี",
      sortable: false,
      minWidth: 200,
      maxWidth: 400,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%"}}>
            {/* <Typography color="primary">1234567890123</Typography> */}
            <Button
              variant="text"
              onClick={() => {
                handleOpenModalHistory(
                  <AccountListHistory handleCancel={handleCloseModalHistory} data={params?.row} />
                );
              }}
            >
              {params.row.number}
            </Button>
          </Box>
        );
      },
      
    },
    {
      field: "type",
      headerName: "ประเภท",
      sortable: false,
      minWidth: 80,
      maxWidth: 100,
      valueFormatter: ({ value }) => {
        return value?.type;
      },
    },
    {
      field: "name",
      headerName: "ชื่อบัญชี",
      sortable: false,
      minWidth: 300,
      maxWidth: 400,
    },
  ];

  // ฟังก์ชันสำหรับสร้างคอลัมน์ใหม่และเพิ่มเข้าไปในคอลัมน์เดิม
  const generateAndAppendRequestColumns = (existingColumns, newColumnNames) => {
    if (newColumnNames === undefined) return [];

    const newColumns = newColumnNames.map((name) => ({
      field: name.toLowerCase(),
      headerName: name,
      sortable: false,
      maxWidth: 250,
      renderCell: (params) => {
        const row = params.row[params.field.toUpperCase()];
        const total = row.total || 0;
        return (
          <Stack alignItems="center">
            <Stack direction="row">({total})</Stack>
            <Stack direction="row">
              {row.status === null && <Chip label="ยังไม่ขอ" size="small" />}
              {row.status !== null && getChip(row.status, { size: "small" })}
            </Stack>
          </Stack>
        );
      },
    }));
    return [...existingColumns, ...newColumns];
  };

  const columns = generateAndAppendRequestColumns(_columns, requestColumns);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const handleDownload = (uri, setActionState) => {
    setLoadingPDF(true);
    setActionState(true);
    api
      .post(
        uri,
        {
          // new_status: "sent_to_bank",
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoadingPDF(false);
        setActionState(false);
      });
  };

  useEffect(() => {
    return () => {
      dispatch(clearResult());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(markLoading(true));
    // SetFetchError(false);
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(fetch(pageSize, 1, {}, fetchId, fetchIdRef, caseId)).catch(
      ({ response }) => {
        console.log("action error");
        if (response && response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.log(errors);
        }
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
        dispatch(clearResult());
        dispatch(markLoading(false));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, caseId, forceReload]);

  // const handleAddCsvResult = (response) => {
  //   handleOpenModal(
  //     <AccountAddCsvResultView
  //       response={response}
  //       handleCancel={handleCloseModal}
  //     />
  //   );
  // };

  // const transformStatusText = (input) => {
  //   // แยกคำที่มีขีดล่างออกเป็นคำต่างๆ
  //   let words = input.split("_");

  //   // ทำให้ตัวอักษรตัวแรกของแต่ละคำเป็นตัวพิมพ์ใหญ่
  //   words = words.map(
  //     (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  //   );

  //   // รวมคำต่างๆ เข้าด้วยกันโดยมีเว้นวรรคระหว่างคำ
  //   return words.join(" ");
  // };

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>{openModal}</Box>
      </Modal>
      <Modal
        open={openModalHistory ? true : false}
        onClose={handleCloseModalHistory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalHistoryStyle}>{openModalHistory}</Box>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <DeleteIcon /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ บัญชีธนาคาร(ID: {cfDel})
      </ConfirmDialog>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ mb: 2 }}
      >
        {/* <RequireRoles roleIds={[2]}>
          <Button
            variant="contained"
            color="success"
            startIcon={<Add />}
            onClick={() => {
              handleOpenModal(
                <AccountAddCsvView
                  handleCancel={handleCloseModal}
                  handleResult={handleAddCsvResult}
                />
              );
            }}>
            เพิ่มบัญชี(CSV)
          </Button>
        </RequireRoles> */}
        {cases.includes("download") && (
          <LoadingButton
            type="button"
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdf />}
            loadingPosition="start"
            loading={pdfDownloading}
            disabled={loadingPDF}
            onClick={() => handleDownload("/api/", setPdfDownloading)}
          >
            ดาวน์โหลด PDF
          </LoadingButton>
        )}
        {cases.includes("create") && (
          <Button
            variant="contained"
            color="success"
            startIcon={<Add />}
            onClick={() => {
              handleOpenModal(
                <AccountAddPage handleCancel={handleCloseModal} />
              );
            }}
          >
            เพิ่มบัญชี
          </Button>
        )}
      </Stack>

      <DataGrid
        disableColumnMenu
        columns={columns}
        rows={rows}
        loading={loading}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        // pagination
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        autoHeight
        getRowHeight={() => 80}
      />
    </>
  );
}
