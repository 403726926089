import { Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import api from "../../../../apis";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";

// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UserForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  setStatus,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [toastInfo, SetToastInfo] = useState(false);
  const qState = useSelector((state) => state.auth.user.permissions);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rows = useSelector((state) => state.setting.permission.data);

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  // console.log(values);
  const {
    isLoading,
    isError,
    data: roles,
  } = useQuery(
    ["roles"],
    () =>
      api.get(`/api/roles`).then((res) => {
        return res.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      })
    // { staleTime: 60 * 1000 }
  );

  const [selectRoles, setSelectroles] = React.useState([]);
  const [statusCheck, setStatusCheck] = React.useState(true);
  const [bank, setBank] = React.useState([]);

  useEffect(() => {
    if (initialValues) {
      setStatusCheck(initialValues.status);
    }
  }, [initialValues]);

  const { data: externaltype } = useQuery(
    ["externaltype"],
    () =>
      api.get(`/api/externaltype`).then((res) =>
        res.data.map((item) => {
          return {
            value: item.value,
            label: item.name,
          };
        })
      )
    // { staleTime: 60 * 1000 }
  );

  const getBank = useCallback(async (type) => {
    await api.get(`/api/banks?type=${type}`).then((res) => {
      const data = res?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: `${item.name}(${item.short_name})`,
        };
      });
      setBank(data);
    });
  }, []);

  useEffect(() => {
    if (values?.external_org_type) {
      if (values?.external_org_type === "Telco") {
        getBank("telco");
      } else {
        getBank(values?.external_org_type);
      }
    }
  }, [getBank, values?.external_org_type]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <FieldInput
            name="email"
            label="Email"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
          <Typography variant="caption" color="gray">
            รองรับอีเมล @police.go.th,@royalthaipolice.go.th หรือ @rtp.go.th
            เท่านั้น
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="password"
            label="รหัสผ่าน"
            required
            controlProps={{
              fullWidth: true,
              type: "password",
            }}
            inputType="password"
            helperText={
              "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="password_confirmation"
            label="ยืนยันรหัสผ่าน"
            required
            controlProps={{
              fullWidth: true,
              type: "password",
            }}
            inputType="password"
            helperText={
              "รหัสผ่านใหม่ กับ ยืนยันรหัสผ่านใหม่ ต้องตรงกันเท่านั้น"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="rank"
            label="คำนำหน้า"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="phone"
            label="โทรศัพท์"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="fname"
            label="ชื่อ"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="lname"
            label="สกุล"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="position"
            label="ตำแหน่ง"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="external_org_type"
            label="ประเภทหน่วยงาน"
            options={externaltype}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            defaultValue={{ label: "Default Option", value: "default" }}
            // inputProps={{ sx: { fullWidth: true } }}
            // controlProp={{ sx: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="card_id"
            label="เลขบัตรประชาชน"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <div className="z-50">
              <Select
                value={{ id: 3, name: "หน่วยงานภายนอก" }}
                isMulti
                isDisabled
                onChange={(e) => setSelectroles(e)}
                options={[{ id: 3, name: "หน่วยงานภายนอก" }]}
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.id}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="เลือกสิทธิ์การใช้งาน"
                styles={{
                  // menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  control: (base) => ({
                    ...base,
                    width: "100%",
                    height: 50,
                    minHeight: 40,
                  }),
                }}
                menuContainerStyle={{ zIndex: 999 }}
                maxMenuHeight={190}
              />
            </div>
            {/* <InputLabel id="demo-multiple-checkbox-label">
              สิทธิ์การใช้งาน
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={permission}
              onChange={handleChange}
              input={<OutlinedInput label="" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {rows?.map((value) => (
                <MenuItem key={value.id} value={value.id}>
                  <Checkbox checked={permission.indexOf(value.id) > -1} />
                  <ListItemText primary={value.name} />
                </MenuItem>
              ))}
            </Select> */}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="z-0">
            <FieldAutoComplete
              name="external_org"
              label="หน่วยงานภายนอก"
              options={bank}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              defaultValue={{ label: "Default Option", value: "default" }}
            />
            {/* <FieldAsyncAutoComplete
              name="external_org"
              label="หน่วยงานภายนอก"
              required
              controlProp={{ fullWidth: true }}
              basePath="/api/banks"
              itemFormat={(row) => {
                return {
                  value: {id: row.id, org_type: row.type},
                  label: `${row.name}(${row.short_name})`,
                };
              }}
            /> */}
          </div>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="status"
              value={statusCheck}
              onChange={(e) => {
                setStatusCheck(e.target.value);
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="ใช้งาน"
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="ไม่ใช้งาน"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        {!readOnly && (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Divider></Divider>
              {!qState?.setting_external?.includes("edit") && (
                <p className="text-red-400 my-2">คุณไม่มีสิทธิ์แก้ไข</p>
              )}
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  // pristine={pristine}
                  onClick={() => {
                    // console.log(selectRoles)
                    setStatus(statusCheck);
                  }}
                  disabled={!qState?.setting_external?.includes("edit")}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    if (onCancel) {
                      onCancel(form);
                      return;
                    }

                    navigate("/settinguserExternal");
                  }}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateEdit = (values, props) => {
  const errors = {};
  const vResult = schemaEdit().validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateRules = () => ({
  rank: Joi.string()
    .max(255)
    .messages({
      "string.base": `กรุณาระบุคำนำหน้า`,
      "any.required": "กรุณาระบุคำนำหน้า",
    })
    .required(),
  card_id: Joi.string()
    .pattern(/^\d{13}$/)
    .messages({
      "string.pattern.base": "เลขบัตรประชาชนต้องมี 13 หลัก",
      "any.required": "กรุณาระบุเลขบัตรประชาชน",
    })
    .required(),
  // laserCode: Joi.string().required(),
  fname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุชื่อ",
    })
    .required(),
  lname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุนามสกุล",
    })
    .required(),
  phone: Joi.string()
    .pattern(/^\d{10}$/)
    .messages({
      "string.pattern.base": "โทรศัพท์ต้องมี 10 หลัก",
      "any.required": "กรุณาระบุเบอร์โทรศัพท์",
    })
    .required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "รูปแบบอีเมลไม่ถูกต้อง",
      "any.required": "กรุณาระบุอีเมล",
    })
    .required(),
  // job_title: Joi.string().max(255),
  external_org_type: Joi.any()
    .messages({
      "any.required": "กรุณาระบุประเภทหน่วยงาน",
    })
    .required(),
  external_org: Joi.any()
    .messages({
      "any.required": "หน่วยงานภายนอก",
      "string.base": "กรุณาระบุหน่วยงานภายนอก",
    })
    .required(),
  password: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
      )
    )
    .messages({
      "string.pattern.base":
        "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้",
      "any.required": "กรุณาระบุรหัส",
    }),

  password_confirmation: Joi.any().valid(Joi.ref("password")).messages({
    "any.only": "รหัสผ่าน กับ ยืนยันรหัสผ่าน ไม่ต้องตรงกันเท่านั้น",
    "any.required": "กรุณาระบุยืนยันรหัสผ่าน",
  }),

  // status: Joi.bool(),
});

export const validateRulesEdit = () => ({
  rank: Joi.string()
    .max(255)
    .messages({
      "string.base": `กรุณาระบุคำนำหน้า`,
      "any.required": "กรุณาระบุคำนำหน้า",
    })
    .required(),
  card_id: Joi.string()
    .pattern(/^\d{13}$/)
    .messages({
      "string.pattern.base": "เลขบัตรประชาชนต้องมี 13 หลัก",
      "any.required": "กรุณาระบุเลขบัตรประชาชน",
    })
    .required(),
  // laserCode: Joi.string().required(),
  fname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุชื่อ",
    })
    .required(),
  lname: Joi.string()
    .max(255)
    .messages({
      "any.required": "กรุณาระบุนามสกุล",
    })
    .required(),
  phone: Joi.string()
    .pattern(/^\d{10}$/)
    .messages({
      "string.pattern.base": "โทรศัพท์ต้องมี 10 หลัก",
      "any.required": "กรุณาระบุเบอร์โทรศัพท์",
    })
    .required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "รูปแบบอีเมลไม่ถูกต้อง",
      "any.required": "กรุณาระบุอีเมล",
    })
    .required(),
  // job_title: Joi.string().max(255),
  external_org_type: Joi.any()
    .messages({
      "any.required": "กรุณาระบุประเภทหน่วยงาน",
    })
    .required(),
  external_org: Joi.any()
    .messages({
      "any.required": "หน่วยงานภายนอก",
      "string.base": "กรุณาระบุหน่วยงานภายนอก",
    })
    .required(),
  password: Joi.string()
    .pattern(
      new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
      )
    )
    .messages({
      "string.pattern.base":
        "ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข และตัวอักขระพิเศษได้",
      "any.required": "กรุณาระบุรหัส",
    }),
  password_confirmation: Joi.any()
    .valid(Joi.ref("password"))
    .when("password", {
      is: Joi.any().valid(null, ""),
      then: Joi.optional().allow(null, ""),
      otherwise: Joi.required(),
    })
    .messages({
      "any.only": "รหัสผ่าน กับ ยืนยันรหัสผ่าน ไม่ต้องตรงกันเท่านั้น",
      "any.required": "กรุณาระบุยืนยันรหัสผ่าน",
    }),

  // status: Joi.bool(),
});

// const schema = () => Joi.object(validateRules()).error(validationMsg());

const schema = () => Joi.object(validateRules());
const schemaEdit = () => Joi.object(validateRulesEdit());

export const mapToInit = (item) => {
  return item;
};
