import {
  Chip,
  Grid,
  Stack,
  Typography
} from "@mui/material";

const Machine = () => {
  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mb: 0 }}>
        <Grid item>
          <Typography variant="h6" color="textSecondary" sx={{ ml: 2 }}>
            Machine Specification
          </Typography>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Grid item>
          <Chip
            color="secondary"
            size="medium"
            variant="outlined"
            label="Asset Information"
            sx={{ ml: 2 }}
          >
            Asset Information
          </Chip>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Grid container spacing={2} sx={{ ml: 1 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary">
              Asset name
            </Typography>
            <Typography variant="body2">WINDOWS-1453-Scranton</Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              OS Version
            </Typography>
            <Typography variant="body2">
              MICROSOFT WINDOWS SERVER 2012
            </Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              Serial Number
            </Typography>
            <Typography variant="body2">
              92643A67-8FE9-49A2-B2B3-B21024DE37E4
            </Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              Domain
            </Typography>
            <Typography variant="body2">WORKGROUP</Typography>
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary">
              Serial number
            </Typography>
            <Typography variant="body2">BITT-YEHE-DDFF-7HHA</Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              Product key
            </Typography>
            <Typography variant="body2">BITT-YEHE-DDFF-7HHA-YUYT</Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              Model
            </Typography>
            <Typography variant="body2">LATITUDE 3490</Typography>
            <br />
            <Typography variant="body2" color="textSecondary">
              Host name
            </Typography>
            <Typography variant="body2">SUPEROP5AGENT55</Typography>
            <br />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default Machine;
