import { CloudDownload, CloudUpload } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";

const Traffic = () => {
  return (
    <>
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Grid item sx={{ mt: 3, mb: 4, ml: 2 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton aria-label="delete" size="large" color="success">
              <CloudDownload fontSize="large" />
            </IconButton>
            <Typography variant="h6" color="textSecondary">
              <b>33.33Mbps</b> DOWNLOAD
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton aria-label="delete" size="large" color="primary">
              <CloudUpload fontSize="large" />
            </IconButton>
            <Typography variant="h6" color="textSecondary">
              <b>33.33Mbps</b> UPLOAD
            </Typography>
          </Stack>
        </Grid>
      </Stack>
    </>
  );
};

export default Traffic;
