import { Save } from "@mui/icons-material";
import { Alert, Button, Divider, Grid, Stack } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import api from "../../../apis";
import bankAccountTypes from "../../../data/selects/bankAccountTypes";
import validationMsg from "../../../helpers/validationMsg";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";

export default function PersonalForm({
  readOnly = false, // mycustom
  handleCancel, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  const [providers, setProviders] = useState([]);
  const mobile = () =>
    providers.map((p) => ({
      value: +p.id,
      label: `${p.name} (${p.short_name})`,
    }));

  useEffect(() => {
    api.get("/api/banks/list/telco").then(({ data }) => {
      setProviders(data);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="provider_id"
            label="ค่ายมือถือ"
            options={mobile()}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="phone_number"
            label="เบอร์มือถือ"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputProps={{ inputProps: { maxLength: 10 } }}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="nationality"
            label="สัญชาติ/ประเทศ"
            required
            options={countryOptions}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            readOnly={readOnly}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="type_id"
            label="ประเภทบัญชี"
            options={bankAccountTypes}
            readOnly={readOnly}
            controlProp={{ fullWidth: true }}
            // required
            // disabled={loading}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <FieldInput
            name="seq"
            label="ลำดับการรับเงิน"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
            inputType="number"
          />
        </Grid> */}
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item xs={12}>
          <FieldInput
            name="notes"
            label="หมายเหตุ"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  variant="outlined"
                  disabled={submitting}
                  onClick={handleCancel}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  provider_id: Joi.number().required(),
  phone_number: Joi.string().pattern(/^\d{10}$/).messages({
    "string.pattern.base": "หมายเลขโทรศัพท์ต้องเป็นตัวเลข 10 หลัก",
    "any.required": "กรุณาระบุหมายเลขโทรศัพท์",

  }).required(),
  // country_code: Joi.string().required(),
  type_id: Joi.number().allow(null),
  notes: Joi.string().max(20).allow(null),
};

const schema = Joi.object(validateRules)

// const schema = Joi.object(validateRules).error(validationMsg());
