import { Alert, Grid, Paper, Snackbar, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../apis";
import RequestNewFormH, {
  validateRules,
} from "./request-forms/RequestNewFormH";

export default function RequestAddPage() {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();
  const [item, setItem] = useState({});

  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  useEffect(() => {
    let isSubscribed = true;
    api
      .get(`api/me`)
      .then(({ data }) => {
        // console.log(data.permisstions.map((v) => v.option.map((v) => v.info)));
        isSubscribed && setItem(data);
      })
      .catch(({ response }) => {
        if (response && response.status === 404) {
          SetFetchError("เกิดข้อผิดพลาด");
        }
        SetFetchError("เกิดข้อผิดพลาด");
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onSubmit = async (formData) => {
    const dataAll = [];
    const dataH = {
      request_type_id: 8,
      letter: "confirmCfr",
      data: {
        address: formData.addressH,
        bank_case_id: formData.bank_case_id,
        bank_id: formData.bank_id,
        date: formData.dateH,
        email: formData.emailH,
        fname: formData.fnameH,
        job_title: formData.job_titleH,
        lname: formData.lnameH,
        no: formData.noH,
        policestation_org_code: formData.policestation_org_codeH,
        rank: formData.rankH,
        signature: formData.signatureH,
        tel: formData.telH,
        victim_card_id: formData.victim_card_id,
        nationality: formData.is_foreigner ? formData.nationality : "TH",
      },
    };
    dataAll.push(dataH);

    const dataSubmit = [];

    const user = {
      rank: formData.rank,
      fname: formData.fname,
      lname: formData.lname,
      job_title: formData.job_title,
      policestation_org_code: formData.policestation_org_code,
      email: formData.email,
      pol_tel: formData.pol_tel,
      address: formData.address,
      profile_signature:
        formData.profile_signature && formData.profile_signature instanceof File
          ? await signatureResize(formData.profile_signature)
          : formData.profile_signature
          ? formData.profile_signature
          : undefined,
    };

    for (let i = 0; i < dataAll.length; i++) {
      let submitData = {};

      if (
        dataAll[i].data.signature &&
        dataAll[i].data.signature instanceof File
      ) {
        // resize before submit
        try {
          const signatureBase64 = await signatureResize(
            dataAll[i].data.signature
          );
          submitData = {
            request_type_id: dataAll[i].request_type_id,
            letter: dataAll[i].letter,
            data: { ...dataAll[i].data, signature: signatureBase64 },
          };
        } catch (e) {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
            signature: "เกิดข้อผิดพลาด",
          };
        }
      } else {
        submitData = {
          request_type_id: dataAll[i].request_type_id,
          letter: dataAll[i].letter,
          data: dataAll[i].data,
        };
      }
      dataSubmit.push(submitData);
    }

    try {
      const res = await api.post(`/api/cases/${caseId}/pol_requests`, {
        user,
        requests: dataSubmit,
      });

      if (res.data.id) {
        navigate(`/cases/${caseId}/requests/${res.data.id}`);
      } else {
        navigate(`/cases/${caseId}/mgt`);
      }
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  return (
    <>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">สร้างคำขอ (ID: {caseId} )</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSubmit}
            component={RequestNewFormH}
            initialValues={mapToInit(item)}
          />
        </Paper>
      </Grid>
    </>
  );
}

const signatureResize = (file) => {
  const fileType = file.type === "image/png" ? "PNG" : "JPEG";
  const compress = file.type === "image/png" ? 100 : 90;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      90,
      50,
      fileType,
      compress,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};

const mapToInit = (item) => {
  return _.pick(item, Object.keys(validateRules));
};
