import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import React, { useState } from "react";

import { Search } from "@mui/icons-material";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";

export default function CaseSearchForm({
  loading, //mycustom
  onReset, //mycustom
  handleSubmit,
  pristine,

  form,

  modifiedSinceLastSubmit,
}) {
  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={12} md={6} lg={3} xl={4}>
          <FieldAsyncAutoComplete
            name="policestation_org_code"
            label="หน่วยงาน"
            required
            controlProp={{ fullWidth: true }}
            basePath="/api/policestations"
            itemFormat={(row) => {
              return {
                value: { org_code: row.ORG_CODE, org_type: row.ORG_TYPE },
                label: row.ORG_ABBR,
              };
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={4}>
          <FieldAsyncAutoComplete
            name="police_name"
            label="ชื่อพนักงานสอบสวน"
            required
            controlProp={{ fullWidth: true }}
            basePath="/api/users"
            itemFormat={(row) => {
              return {
                value: { user_id: row?.id },
                label: `${row?.rank ?? ""} ${row?.fname ?? ""} ${
                  row?.lname ?? ""
                }`,
              };
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6} xl={3}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>

        {/* <Grid item xs={12} md={6} lg={4} xl={4}>
          <FieldAutoComplete
            name="role"
            label="ตำเเหน่ง"
            options={roles || []}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            //   inputProps={{ sx: { fullWidth: true } }}
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={2} mb={2}>
        <Grid item md={12} lg={4}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}
