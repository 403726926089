import { Visibility } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Modal,
  Snackbar,
  Tooltip
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import api from "../../../../apis";
import Hr03AccountView from "./Hr03AccountView";
import Hr03MeetingModal from "./Hr03MeetingModal";
import { getChip } from "./hr03Status";

export default function Step4List({ reload }) {
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // bank account table
  const [pageSize, setPageSize] = React.useState(5);
  const [isError, setIsError] = React.useState(false);
  // const filter = useSelector((state) => state.hr03.meta.filter);
  const qState = useSelector((state) => state.auth.user.permissions);

  // add account modal state

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    setOpenModal(false);
  };

  const {
    isLoading: step4Loading,
    data: step4Data,
    refetch: step4Refetch,
  } = useQuery("step4", () =>
    api
      .post(`api/mgt/hr_reports/rtp_process/check_conflict?confirm_to_save=0`)
      .then((res) => res.data)
      .catch(() => {
        setIsError(true);
      })
  );

  useEffect(() => {
    if (reload) {
      step4Refetch();
    }
  }, [reload, step4Refetch]);

  const columns = [
    qState?.HR03_filter?.includes("view") ?  
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="แสดง">
              <IconButton
                type="button"
                variant="contained"
                size="small"
                // color="primary"
                onClick={() => {
                  handleOpenModal(
                    <Hr03AccountView
                      handleCancel={handleCloseModal}
                      itemId={params.id}
                      hr03Meeting={"hr03Meeting"}
                    />
                  );
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    } : null,
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}
            {params.row.status === "new" && (
              <Chip
                label="กรุณายืนยัน"
                color="warning"
                size="small"
                กรุณายืนยัน
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    fontFamily: "Kanit",
                  },
                }}
              />
            )}
          </Box>
        );
      },
    },
    { field: "hr_type_level", headerName: "ประเภท", width: 130 },
    { field: "tpo_case_id", headerName: "caseID", width: 130 },
    { field: "card_id", headerName: "เลขประจำตัวประชาชน/passport", width: 250 },
    {
      field: "name",
      headerName: "ชื่อ-นามสกุล",
      width: 150,
      renderCell: (params) => {
        return (
          <p>{`${params.row.title ?? ""} ${params.row.name ?? ""} ${
            params.row.surname ?? ""
          }`}</p>
        );
      },
    },
    { field: "bankname", headerName: "ธนาคาร", width: 130 },
    { field: "accountno", headerName: "เลขบัญชี", width: 130 },
    {
      field: "officer",
      headerName: "ชื่อ พงส.",
      width: 150,
      renderCell: (params) => {
        return (
          <p>{`${params.row.rank ?? ""} ${params.row.pol_name ?? ""} ${
            params.row.pol_surname ?? ""
          }`}</p>
        );
      },
    },
  ]?.filter(Boolean);

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
        {qState?.HR03_filter?.includes("confirm") &&   <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <h3 className="text-lg font-medium">Check Conflict</h3>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            return api
              .post(
                `api/mgt/hr_reports/rtp_process/check_conflict?confirm_to_save=1`
              )
              .then((e) => {
                step4Refetch();
                handleOpenModal(
                  <Hr03MeetingModal
                    open={true}
                    handleClose={handleCloseModal}
                    item={e.data}
                    title="ผลลัพธ์ของการประมวลผลตามเงื่อนไข Step 4"
                    step={4}
                    listLength={step4Data?.data?.length}
                  />
                );
              })
              .catch(() => {
                setIsError(true);
              });
          }}
        >
          Process Step 4
        </Button>
      </Box>}
     
      <DataGrid
        disableColumnMenu
        columns={columns}
        rows={step4Data?.data ?? []}
        rowsPerPageOptions={[5, 10, 20]}
        pageSize={pageSize}
        loading={step4Loading}
        pagination
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        sx={{ height:  "800px"  }}
      />

      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>

      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            พบข้อผิดพลาดในการทำรายการ กรุณาลองใหม่อีกครั้ง
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsError(false)}>ปิด</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
