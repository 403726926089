import { Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Grid,
  Snackbar,
  Stack
} from "@mui/material";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../../apis";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";


export default function RequestSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  page,
  pageSize,
}) {
  const status = [
    { value: "1", label: "เปิดใช้งาน" },
    { value: "0", label: "ปิดใช้งาน" },
  ];

  const qState = useSelector((state) => state.auth.user.permissions);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);


  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const [anchorElDowload, setAnchorElDowload] = useState(null);

  const openDowload = Boolean(anchorElDowload);

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

 

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {qState?.setting_request?.includes("search") && (  <Grid container sx={{ mb: 3 }} spacing={2}>
        {showStatusField && (
          <Grid item xs={12} md={4}>
            <FieldAutoComplete
              name="status"
              label="สถานะใช้งาน"
              options={status}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
      </Grid>)
      }
    
      <Grid container spacing={2} mb={2}>
        {qState?.setting_request?.includes("search") && ( <Grid item md={12} lg={4}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>)}

        {qState?.setting_request?.includes("download") && (  <Grid item md={12} lg={qState?.setting_request?.includes("search") ? 8 : 12}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
          <LoadingButton
                id="export-button-excel"
                type="button"
                variant="outlined"
                startIcon={<Download />}
                loadingPosition="start"
                // loading={pdfDownloading}
                // disabled={loadingPDF}
                // onClick={() => handleDownload("/api/", setPdfDownloading)}
                aria-controls={openDowload ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDowload ? "true" : undefined}
                onClick={handleClickDowload}
                loading={loadingFile}
              >
                ดาวน์โหลด
              </LoadingButton>
              <Menu
              id="basic-menu"
              anchorEl={anchorElDowload}
              open={openDowload}
              onClose={handleCloseDownload}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {

                  try {
                    handleCloseDownload(false);
                    setLoadingFile(true);

                    const res = await api.get(
                      "/api/request/types",
                      {
                        responseType: "blob",
                        params: {
                          page_size:pageSize,
                          page: page,
                          export: "excel",
                          ...values
                        },
                      }
                    );

                    let fileName =
                      res?.headers["content-disposition"].split("filename=")[1];

                    fileDownload(res.data, fileName);
                  } catch (error) {
                    console.log("Error");
                  } finally {
                    setLoadingFile(false);
                  }

                 
                }}
              >
                Excel
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  try {
                    handleCloseDownload(false);
                    setLoadingFile(true);

                    const res = await api.get(
                      "/api/request/types",
                      {
                        responseType: "blob",
                        params: {
                          page_size:pageSize,
                          page: page,
                          export: "csv",
                          ...values
                        },
                      }
                    );

                    let fileName =
                      res?.headers["content-disposition"].split("filename=")[1];

                    fileDownload(res.data, fileName);
                  } catch (error) {
                    console.log("Error");
                  } finally {
                    setLoadingFile(false);
                  }
                  
                }}
              >
                CSV
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>)}
      
      </Grid>
    </form>
  );
}
