import {
  Box,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";

const Disk = () => {
  return (
    <>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography align="left">Local disk (C)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">95%</Typography>
            </Grid>
          </Grid>
          <LinearProgress variant="determinate" value={95} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography align="left">Office (D)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">27%</Typography>
            </Grid>
          </Grid>
          <LinearProgress variant="determinate" value={27} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography align="left">My Space (E)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">40%</Typography>
            </Grid>
          </Grid>
          <LinearProgress variant="determinate" value={40} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography align="left">File (F)</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right">20%</Typography>
            </Grid>
          </Grid>
          <LinearProgress variant="determinate" value={20} />
        </Box>
      </Paper>
    </>
  );
};

export default Disk;
