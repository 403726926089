import { CheckCircle, InfoOutlined } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import bankAccountTypes from "../../../../data/selects/bankAccountTypes";
import FieldDataGridCheckBox from "../../../form-fields/FieldDataGridCheckbox";
import FieldDataGridRadio from "../../../form-fields/FieldDataGridRadio";
import LetterFormA from "./sub-forms/LetterFormA";

export default function AccountRequest({ readOnly, values = {}, bankAccounts = [], hiddenUserForm = false }) {
  const [pageSize, setPageSize] = useState(5);
  const authRoles = useSelector((state) => state.auth.user.roles);

  const isAdmin = useMemo(
    () => [2].some((role) => authRoles[role]),
    [authRoles]
  );

  const AccountPicker = isAdmin ? FieldDataGridCheckBox : FieldDataGridRadio;

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
      {
        field: "bank.name",
        headerName: "ธนาคาร",
        sortable: false,
        width: 100,
        valueGetter: (params) => {
          return params.row.bank?.short_name;
        },
      },
      {
        field: "number",
        headerName: "เลขบัญชี",
        sortable: false,
        minWidth: 200,
        maxWidth: 400,
      },
      {
        field: "name",
        headerName: "ชื่อบัญชี",
        sortable: false,
        minWidth: 300,
        maxWidth: 400,
        valueGetter: (params) => {
          return (
            params.row.name + (params.row.note ? `(${params.row.note})` : "")
          );
        },
      },
      {
        field: "type",
        headerName: "ประเภท",
        sortable: false,
        minWidth: 80,
        maxWidth: 100,
        valueGetter: ({ row }) => {
          const accType = bankAccountTypes.find((e) => e.value === row.type_id);
          return accType?.label;
        },
      },
      {
        field: "is_requested",
        headerName: "ส่งคำขอ",
        sortable: false,
        minWidth: 300,
        maxWidth: 400,
        renderCell: (params) => {
          return params.row.is_requested ? <CheckCircle color="success" /> : "";
        },
      },
    ];
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">
          {readOnly ? (
            "บัญชี"
          ) : (
            <>
              {"เลือกบัญชี"}
              {!isAdmin ? "(1 บัญชี)" : " "}
              <Tooltip title="เพื่อให้การตอบกลับของข้อมูลมีประสิทธิภาพและมีความครบถ้วนจึงเปลี่ยนแปลงการรับส่งข้อมูลเป็น 1 คำขอต่อ 1 บัญชี">
                <InfoOutlined color="primary" fontSize="small" />
              </Tooltip>
            </>
          )}
        </Typography>
        <AccountPicker
          name="bank_accounts"
          disableColumnMenu
          columns={columns}
          rows={bankAccounts}
          // loading={loading}
          rowsPerPageOptions={[5, 10]}
          pageSize={pageSize}
          // pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          autoHeight
          parse={(value) => {
            return value
              ? value.map((item) => {
                return { bank_account_id: item };
              })
              : [];
          }}
          format={(value) => {
            return value ? value.map((item) => item.bank_account_id) : [];
          }}
          readOnly={readOnly}
          initialState={{
            columns: {
              columnVisibilityModel: {
                is_requested: !readOnly,
              },
            },
          }}
        />
      </Grid>
      <LetterFormA readOnly={readOnly} hiddenUserForm={hiddenUserForm} />
    </>
  );
}
