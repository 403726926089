import { Delete, List, Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import {
  usersClearResult,
  usersForceReload,
  usersSetFilter,
} from "../../../../ducks/setting";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import UserSearchForm from "./UserSearchForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function SettingUserGroup() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const navigate = useNavigate();
  const qState = useSelector((state) => state.auth.user.permissions);

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(usersClearResult());
      dispatch(usersSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // const fetchIdRef = React.useRef(0);
  // const rows = useSelector((state) => state?.setting?.users?.data);
  // const total = useSelector((state) => state?.setting?.metaUsers?.total);
  const loading = useSelector((state) => state?.setting?.metaUsers?.loading);
  // const forceReload = useSelector(
  //   (state) => state?.setting?.metaUsers?.forceReload
  // );
  // const page = useSelector((state) => state?.setting?.metaUsers?.page);
  // const pageSize = useSelector((state) => state?.setting?.metaUsers?.pageSize);
  const filter = useSelector((state) => state?.setting?.metaUsers?.filter);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  // useEffect(() => {
  //   // Give this fetch an ID
  //   const fetchId = ++fetchIdRef.current;
  //   //filter overide by url status
  //   const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
  //   // Only update the data if this is the latest fetch
  //   dispatch(
  //     usersFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
  //   ).catch((e) => {
  //     SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(usersForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const [openImportModal, setOpenImportModal] = React.useState(false);
  const handleToggleModalImport = () => setOpenImportModal(!openImportModal);
  // console.log({ filter });

  const { data: usersData, refetch: usersRefetch } = useQuery("users", () =>
    api
      .get(
        `/api/users?page=${page + 1}&page_size=${pageSize}&status=${_.get(
          filter,
          "status",
          ""
        )}&search=${_.get(filter, "search", "")}&org_code=${_.get(
          filter,
          "policestation_org_code.org_code",
          ""
        )}&role=${_.get(filter, "role", "")}`
      )
      .then((res) => res.data)
  );

  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`api/users/${cfDel}`)
      .then(() => {
        usersRefetch();
        dispatch(usersForceReload());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  useEffect(() => {
    usersRefetch();
  }, [page, pageSize, filter, usersRefetch]);

  const columns = [
    qState?.setting_user?.includes("delete") ||
    qState?.setting_user?.includes("edit") ||
    qState?.setting_user?.includes("view")
      ? {
          field: "actions",
          headerName: "actions",
          sortable: false,
          width: 100,
          renderCell: (params) => {
            return (
              <>
                <Tooltip title="แก้ไข">
                  <IconButton
                    type="button"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      navigate(`/user/edit/${params.id}`);
                    }}
                  >
                    <List />
                  </IconButton>
                </Tooltip>
                {qState?.setting_user?.includes("delete") && (
                  <Tooltip title="ลบ">
                    <IconButton
                      type="button"
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() => {
                        setCfDel(params.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}

                {/* <IconButton
              type="button"
              variant="contained"
              size="small"
              color="error"
              onClick={() => {
                setCfDel(params.id);
              }}
            >
              <Delete />
            </IconButton> */}
              </>
            );
          },
        }
      : null,
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 70,
    },
    {
      field: "status",
      headerName: "สถานะใช้งาน",
      sortable: true,
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {params.row.status === 0 ? (
                <Chip
                  label="ปิดใช้งาน"
                  color="error"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              ) : (
                <Chip
                  label="ใช้งาน"
                  color="success"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              )}
              {/* <Chip label="ใช้งาน" color="success"  /> */}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "rank",
      headerName: "ยศ",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.rank === null ? "-" : params.row.rank}</>;
      },
    },
    {
      field: "fname",
      headerName: "ชื่อผู้ใช้งาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.fname} {params.row.lname}
          </>
        );
      },
    },
    {
      field: "job_title",
      headerName: "ตำแหน่ง",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.job_title === null ? "-" : params.row.job_title}</>;
      },
    },
    {
      field: "policestation",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.policestation.ORG_ABBR}</>;
      },
    },
    {
      field: "roles",
      headerName: "สิทธิ์ผู้ใช้งาน",
      sortable: true,
      width: 200,
      renderCell: (params) => {
        return <>{params?.row?.roles ? params?.row?.roles : "-"}</>;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่สร้าง",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy") : undefined;
      },
    },
    {
      field: "updated_at",
      headerName: "วันที่อัพเดต",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy") : undefined;
      },
    },
  ].filter(Boolean);

  const onSearchSubmit = (formValues) => {
    dispatch(usersSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Modal
        open={openImportModal}
        onClose={() => handleToggleModalImport()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">รอ API จากทางตำรวจ</h2>
        </Box>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ(ID: {cfDel})
      </ConfirmDialog>
      <Grid item xs={12}>
        <Typography variant="h5">ข้อมูลผู้ใช้งาน</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={UserSearchForm}
            loading={loading}
            onReset={() => dispatch(usersSetFilter({}))}
            showStatusField={reqStatus ? false : true}
            usersData={usersData}
            handleToggleModalImport={handleToggleModalImport}
            page={page}
            pageSize={pageSize}
          />
          <DataGrid
            // density="compact"
            rowCount={_.get(usersData, "total", 0)}
            disableColumnMenu
            columns={columns}
            rows={_.get(usersData, "data", [])}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={(page) =>
              // dispatch(usersChangePage(page))
              setPage(page)
            }
            onPageSizeChange={(pageSize) => {
              console.log({ pageSize });
              setPageSize(pageSize);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.setting.metaUsers.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(usersForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
