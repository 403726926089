import { Download } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import fileDownload from "js-file-download";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../apis";
import {
  dataExternalReportClearResult,
  dataExternalReportFilter,
  dataExternalReportLoading,
  fetchDataExternalReport,
} from "../../../ducks/report";
import DataExternalReportSearchForm from "./DataExternalReportSearchForm";
import { format } from "date-fns";

export default function DataExternalReport() {
  const dispatch = useDispatch();

  const initialValues = {
    start_date: format(new Date(), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
    bank_id: -1,
    request_type_id: -1,
  };

  const [formState, setFormState] = useState(initialValues);

  const fetchIdRef = React.useRef(0);
  const didRequest = React.useRef(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const openDowload = Boolean(anchorElDowload);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [orgName, SetRequestName] = useState();
  const [bankName, SetBankName] = useState();

  const [orgNameLabel, SetRequestNameLabel] = useState();
  const [bankNameLabel, SetBankNameLabel] = useState();

  useEffect(() => {
    return () => {
      dispatch(dataExternalReportClearResult());
      dispatch(dataExternalReportFilter({}));
    };
  }, [dispatch]);

  const report_static_org_external = useSelector(
    (state) => state.auth.user?.permissions?.report_static_org_external ?? []
  );

  const rows = useSelector(
    (state) => state?.report?.data_external_report?.data ?? []
  );

  const loading = useSelector(
    (state) => state.report.metaDataExternalReport?.loading
  );
  const filter = useSelector(
    (state) => state.report.metaDataExternalReport?.filter
  );

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  React.useEffect(() => {
    dispatch(dataExternalReportLoading(true));
    const fetchId = ++fetchIdRef.current;
    dispatch(fetchDataExternalReport(initialValues, fetchId, fetchIdRef))
      .catch(({ response }) => {
        console.log("action error");
        if (response && response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);
          console.error(errors);
        }
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
        dispatch(dataExternalReportClearResult());
      })
      .finally(() => {
        didRequest.current = true;
        dispatch(dataExternalReportLoading(false));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (Object.hasOwn(filter, "start_date")) {
      dispatch(dataExternalReportLoading(true));
      const fetchId = ++fetchIdRef.current;
      dispatch(fetchDataExternalReport(filter, fetchId, fetchIdRef))
        .catch(({ response }) => {
          console.log("action error");
          if (response && response.status === 422) {
            const errors = _.mapValues(response.data.errors, (e) => e[0]);
            console.error(errors);
          }
          SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
          dispatch(dataExternalReportClearResult());
          dispatch(dataExternalReportLoading(false));
        })
        .finally(() => {
          didRequest.current = true;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter]);

  const onSearchFormSubmit = (data) => {
    setFormState(data);
    SetRequestName(orgNameLabel);
    SetBankName(bankNameLabel);
    dispatch(dataExternalReportFilter(data));
  };

  const handleDownload = (exportType) => {
    setLoadingPDF(true);
    setPdfDownloading(true);
    api
      .get(`/api/reports_external_department`, {
        params: {
          start_date: formState.start_date,
          end_date: formState.end_date,
          bank_id: formState.bank_id,
          request_type_id: formState.request_type_id,
          export: exportType,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoadingPDF(false);
        setPdfDownloading(false);
      });
  };

  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };

  const columns = [
    {
      field: "case.request",
      headerName: "จำนวนที่ขอ",
      sortable: false,
      minWidth: 150,
      maxWidth: 340,
      valueGetter: (params) => {
        return params.row.case.request;
      },
      bgColor: "rgba(255, 204, 185, 0.8)",
    },
    {
      field: "case.answer",
      headerName: "จำนวนที่ตอบ",
      sortable: false,
      minWidth: 150,
      maxWidth: 340,
      valueGetter: (params) => {
        return params.row.case.answer;
      },
      bgColor: "rgba(255, 204, 185, 0.8)",
    },
    {
      field: "duration_answer.duration_fastest",
      headerName: "เร็วที่สุด (วัน)",
      sortable: false,
      minWidth: 150,
      maxWidth: 340,
      valueGetter: (params) => {
        return params.row.duration_answer.duration_fastest;
      },
      bgColor: "rgba(142, 222, 161, 0.8)",
    },
    {
      field: "duration_answer.duration_slowest",
      headerName: "ช้าที่สุด (วัน)",
      sortable: false,
      minWidth: 150,
      maxWidth: 340,
      valueGetter: (params) => {
        return params.row.duration_answer.duration_slowest;
      },
      bgColor: "rgba(142, 222, 161, 0.8)",
    },
    {
      field: "duration_answer.duration_average",
      headerName: "เฉลี่ย (วัน)",
      sortable: false,
      minWidth: 150,
      maxWidth: 340,
      valueGetter: (params) => {
        return params.row.duration_answer.duration_average;
      },
      bgColor: "rgba(142, 222, 161, 0.8)",
    },
  ];

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">
          รายงานสถิติข้อมูลหน่วยงานภายนอก (รับ-ส่ง)
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            onSubmit={onSearchFormSubmit}
            component={DataExternalReportSearchForm}
            loading={loading}
            onReset={() => dispatch(dataExternalReportFilter(initialValues))}
            initialValues={initialValues}
            SetRequestNameLabel={SetRequestNameLabel}
            SetBankNameLabel={SetBankNameLabel}
            SetRequestName={SetRequestName}
            SetBankName={SetBankName}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            minHeight: 700,
            width: "100%",
          }}
        >
          <Grid container spacing={2} mb={2} alignItems={"center"}>
            <Grid item md={9}>
              <Typography>
                รายงานสถิติข้อมูลหน่วยงานภายนอก (รับ-ส่ง) -{" "}
                {orgName === "ทั้งหมด" && bankName === "ทั้งหมด"
                  ? orgName
                  : orgName + " - " + bankName}
              </Typography>
            </Grid>
            <Grid item md={3}>
              {report_static_org_external.includes("download") && (
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                  <LoadingButton
                    type="button"
                    variant="outlined"
                    startIcon={<Download />}
                    loadingPosition="start"
                    loading={pdfDownloading}
                    disabled={loadingPDF}
                    aria-controls={openDowload ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openDowload ? "true" : undefined}
                    onClick={handleClickDowload}
                  >
                    ดาวน์โหลด
                  </LoadingButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElDowload}
                    open={openDowload}
                    onClose={handleCloseDownload}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleDownload("excel");
                      }}
                    >
                      Excel
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleDownload("csv");
                      }}
                    >
                      CSV
                    </MenuItem>
                  </Menu>
                </Stack>
              )}
            </Grid>
          </Grid>

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    rowSpan={2}
                    style={{ minWidth: 300 }}
                  >
                    หน่วยงาน
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={2}
                    sx={{ backgroundColor: "rgba(255, 204, 185, 0.8)" }}
                  >
                    จำนวนหมาย
                  </TableCell>
                  <TableCell
                    align="center"
                    colSpan={3}
                    sx={{ backgroundColor: "rgba(142, 222, 161, 0.8)" }}
                  >
                    ระยะเวลาการตอบกลับ (วัน)
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.headerName}
                      align={"center"}
                      style={{ top: 57, minWidth: 150 }}
                      sx={{ backgroundColor: column.bgColor }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell
                        align={"center"}
                        sx={{ backgroundColor: "rgba(255, 236, 230, 0.5)" }}
                      >
                        {row.case.request}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        sx={{ backgroundColor: "rgba(255, 236, 230, 0.5)" }}
                      >
                        {row.case.answer}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        sx={{ backgroundColor: "rgba(226, 255, 233, 0.5)" }}
                      >
                        {row.duration_answer.duration_fastest}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        sx={{ backgroundColor: "rgba(226, 255, 233, 0.5)" }}
                      >
                        {row.duration_answer.duration_slowest}
                      </TableCell>
                      <TableCell
                        align={"center"}
                        sx={{ backgroundColor: "rgba(226, 255, 233, 0.5)" }}
                      >
                        {row.duration_answer.duration_average}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </>
  );
}
