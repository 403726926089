import api from "../apis";
import _ from "lodash";

export const PERMISSION_AUDIT_FETCH = "masterDataList/PERMISSION_AUDIT_FETCH";
export const PERMISSION_AUDIT_LOADING =
  "masterDataList/PERMISSION_AUDIT_LOADING";
export const PERMISSION_AUDIT_CLEAR_RESULT =
  "masterDataList/PERMISSION_AUDIT_CLEAR_RESULT";
export const PERMISSION_AUDIT_CHANGE_PAGE =
  "masterDataList/PERMISSION_AUDIT_CHANGE_PAGE";
export const PERMISSION_AUDIT_CHANGE_PAGE_SIZE =
  "masterDataList/PERMISSION_AUDIT_CHANGE_PAGE_SIZE";
export const PERMISSION_AUDIT_FORCE_RELOAD =
  "masterDataList/PERMISSION_AUDIT_FORCE_RELOAD";
export const PERMISSION_AUDIT_SET_FILTER =
  "masterDataList/PERMISSION_AUDIT_SET_FILTER";

export const MASTER_AUDIT_FETCH = "masterDataList/MASTER_AUDIT_FETCH";
export const MASTER_AUDIT_LOADING = "masterDataList/MASTER_AUDIT_LOADING";
export const MASTER_AUDIT_CLEAR_RESULT =
  "masterDataList/MASTER_AUDIT_CLEAR_RESULT";
export const MASTER_AUDIT_CHANGE_PAGE =
  "masterDataList/MASTER_AUDIT_CHANGE_PAGE";
export const MASTER_AUDIT_CHANGE_PAGE_SIZE =
  "masterDataList/MASTER_AUDIT_CHANGE_PAGE_SIZE";
export const MASTER_AUDIT_FORCE_RELOAD =
  "masterDataList/MASTER_AUDIT_FORCE_RELOAD";
export const MASTER_AUDIT_SET_FILTER = "masterDataList/MASTER_AUDIT_SET_FILTER";

export const USER_AUDIT_FETCH = "masterDataList/USER_AUDIT_FETCH";
export const USER_AUDIT_LOADING = "masterDataList/USER_AUDIT_LOADING";
export const USER_AUDIT_CLEAR_RESULT = "masterDataList/USER_AUDIT_CLEAR_RESULT";
export const USER_AUDIT_CHANGE_PAGE = "masterDataList/USER_AUDIT_CHANGE_PAGE";
export const USER_AUDIT_CHANGE_PAGE_SIZE =
  "masterDataList/USER_AUDIT_CHANGE_PAGE_SIZE";
export const USER_AUDIT_FORCE_RELOAD = "masterDataList/USER_AUDIT_FORCE_RELOAD";
export const USER_AUDIT_SET_FILTER = "masterDataList/USER_AUDIT_SET_FILTER";

export const TRANSACTION_AUDIT_FETCH = "masterDataList/TRANSACTION_AUDIT_FETCH";
export const TRANSACTION_AUDIT_LOADING =
  "masterDataList/TRANSACTION_AUDIT_LOADING";
export const TRANSACTION_AUDIT_CLEAR_RESULT =
  "masterDataList/TRANSACTION_AUDIT_CLEAR_RESULT";
export const TRANSACTION_AUDIT_CHANGE_PAGE =
  "masterDataList/TRANSACTION_AUDIT_CHANGE_PAGE";
export const TRANSACTION_AUDIT_CHANGE_PAGE_SIZE =
  "masterDataList/TRANSACTION_AUDIT_CHANGE_PAGE_SIZE";
export const TRANSACTION_AUDIT_FORCE_RELOAD =
  "masterDataList/TRANSACTION_AUDIT_FORCE_RELOAD";
export const TRANSACTION_AUDIT_SET_FILTER =
  "masterDataList/TRANSACTION_AUDIT_SET_FILTER";

const defaultState = {
  permission_audit: {},
  master_audit: {},
  user_audit: {},
  transaction_audit: {},
  metaPermissionAudit: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  metaMasterAudit: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  metaUserAudit: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
  metaTransactionAudit: {
    loading: false,
    filter: {},
    total: 0,
    forceReload: 0,
    page: 0,
    pageSize: 10,
  },
};

// Reducer
export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case PERMISSION_AUDIT_FETCH:
      return {
        ...state,
        permission_audit: action.payload,
        metaPermissionAudit: {
          ...state.metaPermissionAudit,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case PERMISSION_AUDIT_CHANGE_PAGE:
      return {
        ...state,
        metaPermissionAudit: {
          ...state.metaPermissionAudit,
          page: action.payload,
        },
      };
    case PERMISSION_AUDIT_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaPermissionAudit: {
          ...state.metaPermissionAudit,
          page: 0,
          pageSize: action.payload,
        },
      };
    case PERMISSION_AUDIT_CLEAR_RESULT:
      return {
        ...state,
        permission_audit: action.payload,
        metaPermissionAudit: {
          ...state.metaPermissionAudit,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case PERMISSION_AUDIT_FORCE_RELOAD:
      return {
        ...state,
        metaPermissionAudit: {
          ...state.metaPermissionAudit,
          forceReload: ++state.metaPermissionAudit.forceReload,
        },
      };
    case PERMISSION_AUDIT_SET_FILTER:
      return {
        ...state,
        metaPermissionAudit: {
          ...state.metaPermissionAudit,
          filter: action.payload,
          page: 0,
        },
      };

    case MASTER_AUDIT_FETCH:
      return {
        ...state,
        master_audit: action.payload,
        metaMasterAudit: {
          ...state.metaMasterAudit,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case MASTER_AUDIT_CHANGE_PAGE:
      return {
        ...state,
        metaMasterAudit: {
          ...state.metaMasterAudit,
          page: action.payload,
        },
      };
    case MASTER_AUDIT_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaMasterAudit: {
          ...state.metaMasterAudit,
          page: 0,
          pageSize: action.payload,
        },
      };
    case MASTER_AUDIT_CLEAR_RESULT:
      return {
        ...state,
        master_audit: action.payload,
        metaMasterAudit: {
          ...state.metaMasterAudit,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case MASTER_AUDIT_FORCE_RELOAD:
      return {
        ...state,
        metaMasterAudit: {
          ...state.metaMasterAudit,
          forceReload: ++state.metaMasterAudit.forceReload,
        },
      };
    case MASTER_AUDIT_SET_FILTER:
      return {
        ...state,
        metaMasterAudit: {
          ...state.metaMasterAudit,
          filter: action.payload,
          page: 0,
        },
      };

    case USER_AUDIT_FETCH:
      return {
        ...state,
        user_audit: action.payload,
        metaUserAudit: {
          ...state.metaUserAudit,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case USER_AUDIT_CHANGE_PAGE:
      return {
        ...state,
        metaUserAudit: {
          ...state.metaUserAudit,
          page: action.payload,
        },
      };
    case USER_AUDIT_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaUserAudit: {
          ...state.metaUserAudit,
          page: 0,
          pageSize: action.payload,
        },
      };
    case USER_AUDIT_CLEAR_RESULT:
      return {
        ...state,
        user_audit: action.payload,
        metaUserAudit: {
          ...state.metaUserAudit,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case USER_AUDIT_FORCE_RELOAD:
      return {
        ...state,
        metaUserAudit: {
          ...state.metaUserAudit,
          forceReload: ++state.metaUserAudit.forceReload,
        },
      };
    case USER_AUDIT_SET_FILTER:
      return {
        ...state,
        metaUserAudit: {
          ...state.metaUserAudit,
          filter: action.payload,
          page: 0,
        },
      };

    case TRANSACTION_AUDIT_FETCH:
      return {
        ...state,
        transaction_audit: action.payload,
        metaTransactionAudit: {
          ...state.metaTransactionAudit,
          loading: false,
          total: action.payload.total,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    case TRANSACTION_AUDIT_CHANGE_PAGE:
      return {
        ...state,
        metaTransactionAudit: {
          ...state.metaTransactionAudit,
          page: action.payload,
        },
      };
    case TRANSACTION_AUDIT_CHANGE_PAGE_SIZE:
      return {
        ...state,
        metaTransactionAudit: {
          ...state.metaTransactionAudit,
          page: 0,
          pageSize: action.payload,
        },
      };
    case TRANSACTION_AUDIT_CLEAR_RESULT:
      return {
        ...state,
        transaction_audit: action.payload,
        metaTransactionAudit: {
          ...state.metaTransactionAudit,
          loading: false,
          total: 0,
          page: 0,
        },
      };
    case TRANSACTION_AUDIT_FORCE_RELOAD:
      return {
        ...state,
        metaTransactionAudit: {
          ...state.metaTransactionAudit,
          forceReload: ++state.metaTransactionAudit.forceReload,
        },
      };
    case TRANSACTION_AUDIT_SET_FILTER:
      return {
        ...state,
        metaTransactionAudit: {
          ...state.metaTransactionAudit,
          filter: action.payload,
          page: 0,
        },
      };
    default:
      return state;
  }
}

export const permissionAuditFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(permissionAuditLoading(true));
    try {
      const response = await api.get(`api/audit`, {
        params: {
          page_size: pageSize,
          page: page,
          table_name: "Permission",
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: PERMISSION_AUDIT_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(permissionAuditClearResult());
      throw e;
    }
  };

export const permissionAuditLoading = (isLoading = true) => {
  return { type: PERMISSION_AUDIT_LOADING, payload: isLoading };
};

export const permissionAuditClearResult = () => {
  return { type: PERMISSION_AUDIT_CLEAR_RESULT };
};

export const permissionAuditChangePage = (page) => {
  return { type: PERMISSION_AUDIT_CHANGE_PAGE, payload: page };
};

export const permissionAuditChangePageSize = (pageSize) => {
  return { type: PERMISSION_AUDIT_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const permissionAuditSetFilter = (filterValue) => {
  return { type: PERMISSION_AUDIT_SET_FILTER, payload: filterValue };
};

export const permissionAuditForceReload = () => {
  return { type: PERMISSION_AUDIT_FORCE_RELOAD };
};

export const masterAuditFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(masterAuditLoading(true));
    try {
      const response = await api.get(`api/audit`, {
        params: {
          page_size: pageSize,
          page: page,
          table_name: "Master",
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: MASTER_AUDIT_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(masterAuditClearResult());
      throw e;
    }
  };

export const masterAuditLoading = (isLoading = true) => {
  return { type: MASTER_AUDIT_LOADING, payload: isLoading };
};

export const masterAuditClearResult = () => {
  return { type: MASTER_AUDIT_CLEAR_RESULT };
};

export const masterAuditChangePage = (page) => {
  return { type: MASTER_AUDIT_CHANGE_PAGE, payload: page };
};

export const masterAuditChangePageSize = (pageSize) => {
  return { type: MASTER_AUDIT_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const masterAuditSetFilter = (filterValue) => {
  return { type: MASTER_AUDIT_SET_FILTER, payload: filterValue };
};

export const masterAuditForceReload = () => {
  return { type: MASTER_AUDIT_FORCE_RELOAD };
};

export const userAuditFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(userAuditLoading(true));
    try {
      const response = await api.get(`api/audit`, {
        params: {
          page_size: pageSize,
          page: page,
          table_name: "Users",
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: USER_AUDIT_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(userAuditClearResult());
      throw e;
    }
  };

export const userAuditLoading = (isLoading = true) => {
  return { type: USER_AUDIT_LOADING, payload: isLoading };
};

export const userAuditClearResult = () => {
  return { type: USER_AUDIT_CLEAR_RESULT };
};

export const userAuditChangePage = (page) => {
  return { type: USER_AUDIT_CHANGE_PAGE, payload: page };
};

export const userAuditChangePageSize = (pageSize) => {
  return { type: USER_AUDIT_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const userAuditSetFilter = (filterValue) => {
  return { type: USER_AUDIT_SET_FILTER, payload: filterValue };
};

export const userAuditForceReload = () => {
  return { type: USER_AUDIT_FORCE_RELOAD };
};

export const transactionAuditFetch =
  (pageSize, page, filter = {}, fetchId, fetchIdRef) =>
  async (dispatch) => {
    dispatch(transactionAuditLoading(true));
    try {
      const response = await api.get(`api/audit`, {
        params: {
          page_size: pageSize,
          page: page,
          ...filter,
        },
      });
      if (fetchId === fetchIdRef.current) {
        dispatch({
          type: TRANSACTION_AUDIT_FETCH,
          payload: { ...response.data, pageSize, page },
        });
      }
    } catch (e) {
      const { response } = e;
      if (response && response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);
        console.error(errors);
      }
      dispatch(transactionAuditClearResult());
      throw e;
    }
  };

export const transactionAuditLoading = (isLoading = true) => {
  return { type: TRANSACTION_AUDIT_LOADING, payload: isLoading };
};

export const transactionAuditClearResult = () => {
  return { type: TRANSACTION_AUDIT_CLEAR_RESULT };
};

export const transactionAuditChangePage = (page) => {
  return { type: TRANSACTION_AUDIT_CHANGE_PAGE, payload: page };
};

export const transactionAuditChangePageSize = (pageSize) => {
  return { type: TRANSACTION_AUDIT_CHANGE_PAGE_SIZE, payload: pageSize };
};

export const transactionAuditSetFilter = (filterValue) => {
  return { type: TRANSACTION_AUDIT_SET_FILTER, payload: filterValue };
};

export const transactionAuditForceReload = () => {
  return { type: TRANSACTION_AUDIT_FORCE_RELOAD };
};
