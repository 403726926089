import {
  Alert,
  Button,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";

import { Box } from "@mui/system";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import ReqDownloadListBtn from "../../customs/ReqDownloadListBtn";
import ResponseList from "../../customs/ResponseList";
import RequestStepper from "../../templates/RequestStepper";
import RequestFormReadOnly, {
  mapToInit,
} from "../requests/request-forms/RequestFormReadOnly";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function RequestView({ caseId, reqId, handleCancel }) {
  const requests = useSelector(
    (state) => state.auth.user.permissions.requests ?? []
  );
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);

  // for fetch info
  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  useEffect(() => {
    let isSubscribed = true;

    if (item) {
      return undefined;
    }

    api
      .get(`api/cases/${caseId}/pol_requests/${reqId}`)
      .then(({ data }) => {
        isSubscribed && setItem(data);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        isSubscribed && setLoading(false);
      });

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, reqId]);

  const modalStyle = {
    ...masterModalStyle,
    height: !loading && item ? "100%" : undefined,
    // width: item ? "80%" : undefined,
    width: !loading && item ? "70%" : undefined,
  };

  return (
    <Box sx={modalStyle}>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12} md={6}>
          <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
            <Typography id="modal-modal-title" variant="h6" component="div">
              ส่งคำขอ (ID: {reqId})
            </Typography>
            {!loading && item?.status && getChip(item.status)}
          </Stack>
        </Grid>
        {requests.includes("download") && (
          <Grid item xs={12} md={6} textAlign="right">
            <ReqDownloadListBtn links={item?.links} />
          </Grid>
        )}
      </Grid>
      <Paper sx={{ p: 2, mb: 3 }}>
        {item?.status && (
          <RequestStepper status={item.status} note={item.status_note} />
        )}
        {item?.response_list?.length && (
          <>
            <Divider sx={{ mb: 2 }}>ข้อมูลตอบกลับ</Divider>
            <ResponseList items={item.response_list} />
          </>
        )}
      </Paper>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mb: 3,
        }}
      >
        {loading || !item ? (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        ) : (
          <>
            <Form
              // validate={validate}
              readOnly
              onSubmit={() => undefined}
              component={RequestFormReadOnly}
              initialValues={mapToInit(item)}
            />
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  ปิด
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Box>
  );
}
