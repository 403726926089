import { Save } from "@mui/icons-material";
import { Alert, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import allLetterTypes from "../../../../data/selects/letterTypes";
import { defaultJoiDates } from "../../../../helpers/dateHelper";
import validationMsg from "../../../../helpers/validationMsg";
import FieldAsyncSelect from "../../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldDateTimePicker from "../../../form-fields/FieldDateTimePicker";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import FieldSingleFileDropZone from "../../../form-fields/FieldSingleFileDropZone";
import SubmitBtn from "../../../SubmitBtn";

const receivedFroms = [
  { value: "portal", label: "Web Portal" },
  { value: "email", label: "Email" },
  { value: "msTeam", label: "Microsoft Team" },
  { value: "line", label: "Line" },
  { value: "others", label: "อื่นๆ" },
];

export default function ResponseUploadForm({
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  isFromListRequest,
}) {
  const qState = useSelector((state) => state.auth.user.permissions);
  const extorg_template_upload = useSelector(
    (state) => state.auth.user?.permissions?.extorg_template_upload ?? []
  );

  const letterTypes = useMemo(() => {
    return allLetterTypes.filter((lt) => {
      return [2, 3].includes(lt.value);
    });
  }, []);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={4}>
          <FieldSelect
            name="request_type_id"
            label="ประเภทคำขอ"
            options={letterTypes}
            required
            controlProp={{ sx: { width: "100%" } }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FieldAsyncSelect
            name="bank_id"
            label="หน่วยงานภายนอก"
            required
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            basePath={`/api/banks`}
            // filter={{ type: "bank" }}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FieldDateTimePicker
            name="recived_at"
            label="วันที่รับ"
            required
            pickerProps={{
              inputFormat: "dd/MM/yyyy HH:mm",
              disableFuture: true,
              openTo: "year",
              views: ["month", "day", "hours", "minutes"],
              allowSameDateSelection: true,
            }}
            storeFormat="yyyy-MM-dd HH:mm:00"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldAutoComplete
            name="recived_from"
            label="รับจาก"
            options={receivedFroms}
            controlProp={{ sx: { width: "100%" } }}
            readOnly={isFromListRequest}
            disabled={isFromListRequest}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FieldInput
            name="response_note"
            label="หมายเหตุ"
            controlProps={{ fullWidth: true }}
            inputProps={{ multiline: true, maxRows: 6, minRows: 2 }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1">Template file *</Typography>
          <FieldSingleFileDropZone
            name="template_file"
            accept="text/csv,text/plain,application/zip"
            placeholder={
              <>
                Drag 'n' drop some files here, or click to select files <br />
                (*.csv, *.txt, *.zip) only (15 MB)
              </>
            }
            // 10 MB
            dropZoneProps={{ maxSize: 1048576 * 15 }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1">เอกสารต้นฉบับ</Typography>
          <FieldSingleFileDropZone
            name="response_letter_file"
            accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document , application/pdf"
            placeholder={
              <>
                Drag 'n' drop some files here, or click to select files <br />
                (*.pdf, *.doc, *.docx ) only (5 MB)
              </>
            }
            // resizeImage={resizeFile}
            // shouldResizeImage={(imageFile) => {
            //   return (imageFile.size / 1048576).toFixed(2) > 1;
            // }}
            dropZoneProps={{ maxSize: 1048576 * 5 }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Divider></Divider>
        </Grid>
        <Grid item md={12}>
          <Stack direction="row" spacing={2}>
            {(extorg_template_upload.includes("create") ||
              qState?.template_upload?.includes("create")) && (
              <SubmitBtn
                variant="contained"
                startIcon={<Save />}
                submitting={submitting}
                pristine={pristine}
              >
                บันทึก
              </SubmitBtn>
            )}

            <Button
              variant="outlined"
              disabled={submitting || _.isEmpty(values)}
              onClick={(event) => {
                form.restart({});
              }}
            >
              clear
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  console.log("values=>>", values);
  const errors = {};
  const schema = Joi.object(validateRules()).error(
    validationMsg({ dateFormat: { recived_at: "dd/MM/yyyy HH:mm" } })
  );
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  console.log("vResult=>>", vResult.error);
  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  if (
    !errors.template_file &&
    values.template_file?.size &&
    (values.template_file.size / 1024000).toFixed(2) > 10
  ) {
    errors.template_file = "ขนาดไม่เกิน 10 MB";
    errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
  }

  if (
    !errors.response_letter_file &&
    values.response_letter_file?.size &&
    (values.response_letter_file.size / 1024000).toFixed(2) > 5
  ) {
    errors.response_letter_file = "ขนาดไม่เกิน 5 MB";
    errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
  }

  return errors;
};

export const validateRules = () => ({
  bank_id: Joi.number().required(),
  request_type_id: Joi.number().allow(2, 3).required(),
  recived_at: Joi.date()
    .min(defaultJoiDates.minDateTime)
    .max(new Date())
    .required(),
  recived_from: Joi.string().max(100),
  response_note: Joi.string().max(500),
  template_file: Joi.any().required(),
  response_letter_file: Joi.any(),
});

// instant upload
// const resizeFile = (file) =>
//   new Promise((resolve) => {
//     Resizer.imageFileResizer(
//       file,
//       1920,
//       1080,
//       "jpeg",
//       90,
//       0,
//       (uri) => {
//         resolve(uri);
//       },
//       "file"
//     );
//   });
