import { Paper, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../apis";
import { telephoneForceReload } from "../../../ducks/personalList";
import TelephoneForm, { validate } from "./TelephoneForm";

export default function TelephoneAddPage({ handleCancel, tsFieldName }) {
  const params = useParams();
  const caseId = parseInt(params.id);

  const dispatch = useDispatch();

  const onSubmit = (formValues) => {
    if (formValues.notes === undefined) formValues.notes = null;

    return api
      .get(
        `/api/cases/${caseId}/telephone_check?telephone=${formValues.phone_number}`
      )
      .then(({ data }) => {
        if (data.status < 0) {
          return {
            phone_number: data.message,
            [FORM_ERROR]: undefined,
          };
        } else {
          api
            .post(`/api/cases/${caseId}/telephones`, formValues)
            .then(({ data }) => {
              dispatch(telephoneForceReload());
              handleCancel();
            })
            .catch(({ response }) => {
              if (response.status === 422) {
                const errors = _.mapValues(response.data.errors, (e) => e[0]);
                console.error(errors);
              } else {
                return {
                  [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
                };
              }
            });
        }
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        gutterBottom
      >
        เพิ่มเบอร์โทรศัพท์
      </Typography>
      <Paper
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Form
          validate={validate}
          onSubmit={onSubmit}
          component={TelephoneForm}
          handleCancel={handleCancel}
        />
      </Paper>
    </>
  );
}
