import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Form } from "react-final-form";
import ResponseUploadForm, { validate } from "./ResponseUploadForm";
import jsonToFormData from "json-form-data";
import api from "../../../../apis";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import AlertIconDialog from "../../../dialogs/AlertIconDialog";
import { useLocation } from "react-router-dom";

export default function ResponseUploadPage({ isExternal = false }) {
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [requestTypeId, setRequestTypeId] = useState(null);
  const [bankId, setBankId] = useState(null);
  const [isFromListRequest, setIsFromListRequest] = useState(false);

  // const [dialogOpen, setDialogOpen] = useState(null);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const onSubmit = async (formValues, form) => {
    const obj = {
      ...formValues,
    };
    try {
      const { data } = await api.post(
        isExternal
          ? `/api/external/TemplateUpload`
          : `/api/mgt/upload_template`,
        jsonToFormData(obj)
      );
      setDialogOpen(
        <List>
          <ListItem disablePadding>
            <ListItemText primary={`ประเภท: ${data.type}`} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={`จำนวนรายการทั้งหมด: ${data.response_letter}`}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={`จำนวนรายการที่ update: ${data.updated_data}`}
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary={`เวลาเฉลี่ย: ${data.avgDate}`} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText>
              <Typography>จำนวนรายการที่ส่ง email:</Typography>
              <Typography>
                (สำเร็จ:{" "}
                <Typography component="span" color="success.main">
                  {data.sent_mail?.success}
                </Typography>
                ,{" "}
                <Typography
                  component="span"
                  color={data.sent_mail?.fail && "error.main"}
                >
                  ล้มเหลว: {data.sent_mail?.fail}
                </Typography>
                ,{" "}
                <Typography
                  component="span"
                  color={data.sent_mail?.email_invalid && "error.main"}
                >
                  email ไม่ถูกต้อง: {data.sent_mail?.email_invalid}
                </Typography>
                )
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      );

      form.restart(
        _.omit(formValues, ["template_file", "response_letter_file"])
      );
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else if (
        response.status === 500 &&
        response.data?.message === "template file not valid"
      ) {
        return {
          [FORM_ERROR]: response.data.message,
          template_file: "รูปแบบไม่ถูกต้อง",
        };
      } else if (
        response.status === 500 &&
        response.data?.message ===
          "recived date must be greater than request date"
      ) {
        return {
          [FORM_ERROR]: response.data.message,
          recived_at: "ไม่ควรก่อนวันออกหมาย",
        };
      } else if (response.status === 504) {
        return {
          [FORM_ERROR]:
            "connection timeout (เนื่องจากใช้เวลาประมวลผลนาน) โปรดติดตามผลลัพธ์ผ่าน line notif",
        };
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  useEffect(() => {
    if (location) {
      setIsFromListRequest(_.get(location, "state.setIsFromListRequest"));
      const request_type_id = _.get(location, "state.request_type_id");
      setRequestTypeId(request_type_id);
      const bank_id = _.get(location, "state.bank_id");
      setBankId(bank_id);
    }
  }, [location]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5"> Template Upload </Typography>
        <Typography variant="subtitle1">
          ส่งข้อมูลตอบกลับเข้าสู่ระบบในรูปแบบ template
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <AlertIconDialog
            open={dialogOpen ? true : false}
            onClose={handleDialogClose}
            title="ระบบได้รับข้อมูลแล้ว"
          >
            <Typography>{dialogOpen}</Typography>
          </AlertIconDialog>
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={ResponseUploadForm}
            initialValues={{
              request_type_id: [2, 3].includes(requestTypeId)
                ? requestTypeId
                : undefined,
              bank_id: bankId,
              recived_from: isFromListRequest ? "portal" : undefined,
            }}
            isFromListRequest={isFromListRequest}
          />
        </Paper>
      </Grid>
    </>
  );
}
