import {
  Alert,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  externalChangePage,
  externalChangePageSize,
  externalClearResult,
  externalFetch,
  externalForceReload,
  externalSetFilter,
} from "../../../ducks/external";

import {
  FileDownload,
  Refresh,
  UploadFile,
  Visibility,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import fileDownload from "js-file-download";
import { Form } from "react-final-form";
import {
  Link,
  Navigate,
  useMatch,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";
import RequestLetterSearchForm from "./RequestLetterSearchForm";

export default function RequestListPage() {
  const isInternal = useSelector(
    (state) => state.auth.user?.isInternal ?? false
  );
  const extorg_request_list = useSelector(
    (state) => state.auth.user?.permissions?.extorg_request_list ?? []
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status: reqStatus } = useParams();

  let resolved = useResolvedPath("/admin/request-mgt");
  let isAdminPath = useMatch({ path: resolved.pathname, end: false });

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(externalClearResult());
      dispatch(externalSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = useRef(0);
  const rows = useSelector((state) => state.external.data);
  const total = useSelector((state) => state.external.meta.total);
  const loading = useSelector((state) => state.external.meta.loading);
  const forceReload = useSelector((state) => state.external.meta.forceReload);
  const page = useSelector((state) => state.external.meta.page);
  const pageSize = useSelector((state) => state.external.meta.pageSize);
  const filter = useSelector((state) => state.external.meta.filter);
  const [anchorElDowload, setAnchorElDowload] = useState(null);
  const [csv, setCsv] = useState(null);
  const [pdf, setPdf] = useState(null);
  const openDowload = Boolean(anchorElDowload);

  // const [rowsState, setRowsState] = useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(
      externalFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
    ).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(externalForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const renderAction = ({ row }) => {
    if (!isAdminPath) {
      // same as RequestListView.js
      return (
        <>
          {extorg_request_list.includes("view") && (
            <IconButton
              component={Link}
              to={`/requestletter/${row.case_id}/requests/${row.id}`}
              type="button"
              variant="contained"
              size="small"
            >
              <Visibility />
            </IconButton>
          )}
          {(row?.export_template?.csv || row?.export_template?.pdf) &&
            (!isInternal || extorg_request_list.includes("view")) && (
              <IconButton
                type="button"
                variant="contained"
                size="small"
                aria-controls={openDowload ? "menu-dowload" : undefined}
                aria-haspopup="true"
                aria-expanded={openDowload ? "true" : undefined}
                onClick={(event) => {
                  setAnchorElDowload(event.currentTarget);
                  setCsv(row?.export_template?.csv);
                  setPdf(row?.export_template?.pdf);
                }}
              >
                <FileDownload />
              </IconButton>
            )}

          {extorg_request_list.includes("upload") &&
            row.status.includes("sent_to_bank") && (
              <IconButton
                type="button"
                variant="contained"
                size="small"
                onClick={() =>
                  navigate("/external/responses/upload", {
                    state: {
                      request_type_id: row.request_type_id,
                      bank_id: row.bank_id,
                      setIsFromListRequest: true,
                    },
                  })
                }
              >
                <UploadFile />
              </IconButton>
            )}
        </>
      );
    }

    let modalView = null;

    return (
      <IconButton
        type="button"
        variant="contained"
        size="small"
        onClick={() => {
          handleOpenModal(modalView);
        }}
      >
        <Visibility />
      </IconButton>
    );
  };

  const columns = [
    ...(!isInternal ||
    extorg_request_list.includes("view") ||
    extorg_request_list.includes("upload")
      ? [
          {
            field: "actions",
            headerName: "actions",
            sortable: false,
            width: 150,
            renderCell: (params) => {
              return (
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="left"
                  sx={{ width: "100%" }}
                >
                  {renderAction(params)}
                </Stack>
              );
            },
          },
        ]
      : []),
    {
      field: "id",
      headerName: "ID",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: false,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}{" "}
          </Box>
        );
      },
    },
    {
      field: "detail_id",
      headerName: "setid",
      sortable: false,
      maxWidth: 70,
    },
    {
      field: "request_type.name",
      headerName: "ประเภทคำขอ",
      sortable: false,
      minWidth: 280,
      maxWidth: 300,
      valueGetter: (params) => {
        return params.row.request_type?.name;
      },
    },
    {
      field: "bank.name",
      headerName: "หน่วยงานภายนอก",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        return params.row.bank?.short_name;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ขอ",
      sortable: false,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
    {
      field: "description",
      headerName: "รายละเอียด",
      sortable: false,
      minWidth: 300,
      maxWidth: 400,
      valueGetter: (params) => {
        return params.row.detail?.no;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    dispatch(externalSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Menu
        id="menu-dowload"
        anchorEl={anchorElDowload}
        open={openDowload}
        onClose={() => {
          setAnchorElDowload(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {csv && (
          <MenuItem
            onClick={async () => {
              const res = await api.get(csv, {
                responseType: "blob",
              });
              fileDownload(res.data, "export_template.csv");
            }}
          >
            Template
          </MenuItem>
        )}

        {pdf && (
          <MenuItem
            onClick={async () => {
              const res = await api.get(pdf, {
                responseType: "blob",
              });
              fileDownload(res.data, "export_pdf.pdf");
            }}
          >
            {" "}
            หมายขอข้อมูล
          </MenuItem>
        )}
      </Menu>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">รายการหมายคำขอ (หน่วยงานภายนอก)</Typography>
        <Typography variant="body1">
          รายการแจ้งหมายคำขอมายังธนาคาร
          เพื่อต้องการข้อมูลการทำธุรกรรมทางอิเล็กทรอนิกส์
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={RequestLetterSearchForm}
            loading={loading}
            onReset={() => dispatch(externalSetFilter({}))}
            showStatusField={reqStatus ? false : true}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(externalChangePage(page))}
            onPageSizeChange={(pageSize) =>
              dispatch(externalChangePageSize(pageSize))
            }
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.mgtRequestList.meta.loading);

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(externalForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
